function addProductItem(item) {
  gbAddBagTraceFrom('getProductFrom', {
    goods_id: item.product.goods_id
  })
}
window.GB_analysis_obj = {
  saveForLater: function (json) {
    var item = json.item
    if (json.value) {
      addProductItem(item, 'remove')
    }
  },
  handleQty: function (json) {
    var _item = json.item,
        old_num = json.old_num

    let quantity = Math.abs(_item.quantity - old_num)
    addProductItem({ ..._item, quantity })
  },
  deleteItem: function (json) {
    if (json.value) {
      addProductItem(json.item, 'remove')
    }
  },
  editUpdate: function(prev, res) {
    var spu = prev.product.productRelationID
    var curr = res.info.info.carts.filter(function(item) {
      return item.product.productRelationID = spu
    })[0]
    if(curr && curr.product.goods_sn != prev.product.goods_sn) {
      addProductItem(prev, 'remove')
      addProductItem(curr, 'add')
    }
  }
}

