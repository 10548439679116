<template>
  <div
    v-tap="{
      id: '1-7-2-21',
      data: {
        order_id: order.billno,
        benefit_list: savedPrice ? 'discount' : 'common',
        button_type: 'body'
      }
    }"
    v-expose="{
      id: '1-7-2-20',
      data: {
        order_id: order.billno,
        benefit_list: savedPrice ? 'discount' : 'common',
      }
    }"
    class="empty-unpaid-container"
    @click="handleViewOrder"
  >
    <div class="unPaid-title">
      <span class="unPaid-tip">{{ language.SHEIN_KEY_PC_27591 }}</span>
      <span class="unPaid-total">
        <span class="unPaid-amount-tip">{{ language.SHEIN_KEY_PC_30611 }}</span>
        <span class="unPaid-amount">{{ ': ' + total }}</span>
      </span>
    </div>
    <div class="unPaid-content">
      <div class="order-imgs">
        <img
          v-for="(img, i) in imgList"
          :key="i"
          :src="img.goods_img"
          :alt="img.goods_name"
          class="order-img-item not-fsp-element"
        />
        <div
          v-if="totalNums"
          class="num-mask"
        >
          {{ totalNums }}
        </div>
      </div>
      <div class="content-right">
        <div class="order-save">
          <div
            v-if="savedPrice"
            class="order-save-content"
          >
            {{ `${language.SHEIN_KEY_PC_30673}: ` }}
            <em class="order-saved-price">{{ savedPrice }}</em>
          </div>
          <div
            v-if="showCountDown"
            class="order-count-down"
          >
            <sui_icon_hourglass_12px_1
              size="10px"
              color="#FA6338"
            />
            <count-down-time
              :time-stamp="order.expire_countdown"
              show-type="inline-promotion"
              font-size="10px"
              not-hide
              not-reload
              class="count-down"
            />
          </div>
        </div>
        <sui_icon_viewmore_right_40px
          class="order-jump"
          size="24px"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineExpose, computed, onMounted, defineEmits } from 'vue'
import { getUnPaidImgs } from './unPaidOrderTools.js'
import { sui_icon_hourglass_12px_1, sui_icon_viewmore_right_40px } from '@shein-aidc/icon-vue3'
import { setCache, UN_PAID_ORDER_CACHE_KEY } from 'public/src/pages/cart_new/components/cancelOrderTip/utils/cache.js'

const props = defineProps({
  order: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(['update-order', 'jump-to-order'])

const imgList = computed(() => {
  return getUnPaidImgs(props.order?.order_goods_list || [], 4)
})
const totalNums = computed(() => {
  let count = props.order?.order_goods_num
  if(props.order?.order_goods_list?.length == 1) return 0
  if(count > 99) return '99+'
  if(count > 4) return count // 仅有4张图不展示数字
  return 0
})
const savedPrice = computed(() => {
  if(props.order?.total_discount_new?.amount <= 0) return ''
  return props.order?.total_discount_new?.amountWithSymbol
})
const total = computed(() => {
  return props.order?.currency_pay_amount?.amountWithSymbol
})
const showCountDown = computed(() => {
  return props.order?.expire_countdown >= (new Date().getTime()) / 1000
})
function handleViewOrder() {
  const billno = props.order?.billno
  let queryParams = {
    from_type: 7
  }
  if(props.order?.payment_method != 'cod'){
    queryParams.show_selected_pay_method = 1
  }
  emit('jump-to-order', billno, queryParams)
}
onMounted(() => {
  if(!props.order?.billno) {
    emit('update-order') // 客户端渲染时, 未支付订单信息为空时，触发更新
  }
  setCache(props.order?.billno, UN_PAID_ORDER_CACHE_KEY)
})
defineExpose({
  imgList,
  totalNums
})
</script>
<style lang="less" scoped>
.empty-unpaid-container {
  padding: 10px;
  margin-bottom: -26px;
  width: 360px;
  margin-top: 50px;
  background: #f6f6f6;
  cursor: pointer;
}
.unPaid-title {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  gap: 8px;
  font-size: 14px;
  color: #161616;
  font-weight: 600;
  .unPaid-tip {
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 18px;
  }
  .unPaid-total {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
    max-width: calc(100% - 28px);
  }
  .unPaid-amount-tip {
    flex-shrink: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 18px;
  }
  .unPaid-amount {
    flex-shrink: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.unPaid-content {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-top: 6px;
  width: 100%;
  overflow: hidden;
}
.order-imgs {
  display: flex;
  position: relative;
  gap: 6px;
  .order-img-item {
    width: 54px;
    height: 54px;
  }
  .num-mask {
    width: 54px;
    height: 54px;
    line-height: 54px;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    font-size: 14px;
    font-weight: 600;
  }
}
.content-right {
  display: flex;
  align-items: center;
  gap: 2px;
  overflow: hidden;
  .order-save {
    flex-shrink: 1;
    overflow: hidden;
    text-align: right;
  }
  .order-jump {
    flex-shrink: 0;
  }
  .order-count-down {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.count-down {
  display: inline-block;
  margin-left: 2px;
  margin-top: unset;
  font-size: 10px;
  &::v-deep span.count-number {
    background: unset;
    font-weight: 500;
  }
  &::v-deep span.time-content {
    color: @sui_color_discount;
    /* stylelint-disable-next-line selector-max-type */
    span { color: @sui_color_discount; }
  }
}
.order-save-content {
  width: 100%;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  .order-saved-price {
    color: @sui_color_discount;
    font-weight: 700;
  }
}
</style>
