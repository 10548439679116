import schttp from 'public/src/services/schttp'


export const matchStoreJumpUrl = (params) => {
  return schttp({
    url: `/api/store/matchStoreJumpUrl/get`,
    params: {
      storeCode: params.join(',')
    }
  }).then(res => {
    let result = {}
    if (res.code == 0) {
      result = res?.links || {}
    }
    return result
  }).catch(() => {
    return {}
  })
}

export const modifyCartCheckStatus = (params = {}) => {
  if(params.editCartId) delete params.editCartId
  return schttp({
    url: `/api/cart/modifyCartCheckStatus/update`,
    method: 'POST',
    data: {
      ...params,
      cart_prime_product_code: window.cart_prime_product_code || ''
    }
  }).catch(() => {
    return { code: -1 }
  })
}

export const updateCart =  (params = {}) => {
  return schttp({
    url: '/api/cart/checkcart/get',
    params: {
      ...params,
      cart_prime_product_code: window.cart_prime_product_code || ''
    }
  })
}

export const getSwitchPopover = (params = []) => {
  return schttp({
    url: '/api/cart/postSpuProductsCart/get',
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      accept: 'application/json, text/plain, */*'
    },
    data: params
  })
}
