<template>
  <div
    v-if="shippingTip"
    class="shipping-header-container"
    da-expose-code="cart-self-shop-shipping"
  >
    <div
      class="shipping-header"
      :da-event-expose="shippingInfo.isFirstFreeShipping ? '' : shippingInfo.isLadder ? '1-7-5-5' : '1-7-5-3'"
      :data-origin_price="shippingAnalysisParam.origin_price"
      :data-shipping_method="shippingAnalysisParam.shipping_method"
      :data-is_fullshippingactivity="shippingAnalysisParam.is_fullshippingactivity"
      :data-step_fee="shippingAnalysisParam.step_fee"
      :data-num_price="shippingAnalysisParam.num_price"
      :data-charging_type="shippingAnalysisParam.charging_type"
      :data-shipping-free-type="'3'"
      :data-mall_code="mallCode"
    >
      <sui_icon_shipping_16px size="16px" />
      <div class="shipping-content">
        <span
          class="shipping-tip"
          v-html="shippingTip"
        ></span>
        <ClientOnly>
          <count-down-time
            v-if="firstFreeShipping.showCountDown && firstFreeShipping.countDownTime > 0"
            :time-stamp="firstFreeShipping.countDownTime"
            :is-left-time="true"
          />
        </ClientOnly>
      </div>
      <span
        v-if="shippingInfo.showShipSupplement"
        class="shipping-operation"
        role="button"
        tabindex="0"
        da-event-expose="1-7-1-21"
        da-event-click="1-7-1-20"
        :data-shipping-free-type="shippingInfo.isFirstFreeShipping ? '' : '3'"
        :data-mall_code="mallCode"
        @keydown.stop.enter="clickCartAdd"
        @click="clickCartAdd"
      >
        {{ language.SHEIN_KEY_PC_15215 }}
        <sui_icon_more_right_12px
          :is-rotate="locals.GB_cssRight"
          size="12px"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter'
import { sui_icon_shipping_16px, sui_icon_more_right_12px } from '@shein-aidc/icon-vue3'

daEventCenter.addSubscriber({ modulecode: '1-7-1' })
daEventCenter.addSubscriber({ modulecode: '1-7-5' })
const daEventExpose = daEventCenter.getExposeInstance()

export default {
  name: 'PlatformShipping',
  components: {
    sui_icon_shipping_16px,
    sui_icon_more_right_12px
  },
  props: {
    sortKey: {
      type: String,
      default: ''
    },
    mallCode: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState(['language', 'cartInfo', 'mallShippingInfo', 'ABTParams', 'locals']),
    ...mapGetters(['mallShippingAnalysisParam']),
    shippingInfo(){
      return this.mallShippingInfo[this.mallCode] || {}
    },
    firstFreeShipping() {
      return this.shippingInfo.isFirstFreeShipping ? this.shippingInfo.firstFreeShipping : {}
    },
    shippingTip() {
      return this.firstFreeShipping.firstFreeShippingTip || this.shippingInfo.shippingTip
    },
    shippingAnalysisParam() {
      return this.mallShippingAnalysisParam[this.mallCode] || {}
    },
  },
  mounted() {
    this.initExpose()
  },
  methods: {
    ...mapMutations(['updateAddForCoupon', 'updateField']),
    clickCartAdd: function (){
      const addType = this.firstFreeShipping.firstFreeShippingTip ? 998 : 999
      this.updateField({ key: 'freeShippingAddOnType', value: this.mallCode })
      this.updateField({ key: 'isMoreMallFreeShippingAddOn', value: true })
      this.updateAddForCoupon({ 
        show: true, 
        type: 'AddOnV2', // V2版本走重构后的弹窗
        promotion: {
          promotion_id: addType,
        },
      })
    },
    initExpose() {
      daEventExpose.subscribe && daEventExpose.subscribe({
        keycode: 'cart-self-shop-shipping`1-7-1-21|1-7-5-3|1-7-5-5',
      })
    },
  }
}
</script>

<style lang="less">
.shipping-header-container{
  background-color: @sui_color_safety_bg;
  .shipping-header{
    color: #222222;
    display: flex;
    background: @sui_color_safety_bg;
    padding: 12px 0;
    align-items: center;
    justify-content: space-between;
  }
  .shipping-content{
    flex: 1;
    padding: 0 9px;
  }
  .shipping-tip{
    font-size: 13px;
    line-height: 1.5;
    word-break: break-word;
    //strong{
    //  font-weight: 500;
    //  color: #C44A01;
    //}
  }
  .shipping-operation{
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }
}
</style>
