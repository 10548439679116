<template>
  <div class="empty-icon fsp-element">
    <svg
      width="101"
      height="106"
      viewBox="0 0 75 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M57.0084 32.9753L56.5124 32.9127L57.0084 32.9753ZM54.3524 54.0241L54.8485 54.0867L54.3524 54.0241ZM24.1769 54.0241L23.6808 54.0867L24.1769 54.0241ZM25.169 55.399H53.3603V54.399H25.169V55.399ZM54.8485 54.0867L57.5045 33.0379L56.5124 32.9127L53.8563 53.9615L54.8485 54.0867ZM56.0163 31.3501H22.513V32.3501H56.0163V31.3501ZM21.0248 33.0379L23.6808 54.0867L24.673 53.9615L22.0169 32.9127L21.0248 33.0379ZM22.513 31.3501C21.61 31.3501 20.9117 32.142 21.0248 33.0379L22.0169 32.9127C21.9792 32.6141 22.212 32.3501 22.513 32.3501V31.3501ZM57.5045 33.0379C57.6176 32.142 56.9193 31.3501 56.0163 31.3501V32.3501C56.3173 32.3501 56.5501 32.6141 56.5124 32.9127L57.5045 33.0379ZM53.3603 55.399C54.1161 55.399 54.7538 54.8366 54.8485 54.0867L53.8563 53.9615C53.8248 54.2115 53.6122 54.399 53.3603 54.399V55.399ZM25.169 54.399C24.9171 54.399 24.7045 54.2115 24.673 53.9615L23.6808 54.0867C23.7754 54.8366 24.4132 55.399 25.169 55.399V54.399Z"
        fill="#666666"
      />
      <ellipse
        cx="26.4998"
        cy="64.5"
        rx="1.99994"
        ry="2"
        stroke="#666666"
      />
      <ellipse
        cx="51.9998"
        cy="64.5"
        rx="1.99994"
        ry="2"
        stroke="#666666"
      />
      <mask
        id="mask0_87_24990"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="12"
        y="16"
        width="20"
        height="19"
      >
        <rect
          x="12.8748"
          y="16.1001"
          width="18.7228"
          height="18.7228"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_87_24990)">
        <path
          d="M24.3616 56.1116L24.4159 56.5501H24.8578H58.6119C58.5196 62.7821 56.7036 68.8706 53.3604 74.1385C49.928 79.5472 45.0274 83.8676 39.2311 86.5952C33.4349 89.3227 26.9822 90.3447 20.6268 89.5418C14.2714 88.7389 8.27556 86.1443 3.3397 82.061C-1.59616 77.9777 -5.26831 72.5743 -7.24786 66.4819C-9.2274 60.3895 -9.43261 53.8596 -7.83952 47.6549C-7.66975 46.9937 -7.48552 46.2537 -7.28628 45.4535C-6.53195 42.4237 -5.56255 38.5302 -4.34882 34.7767C-3.58232 32.4063 -2.72255 30.1057 -1.76488 28.1271C-0.844211 26.225 0.151824 24.6529 1.21793 23.6001H19.0074C19.7645 23.6001 20.4029 24.1643 20.496 24.9156L24.3616 56.1116Z"
          stroke="#666666"
        />
      </g>
      <path
        d="M58.4918 10.475C59.08 10.475 59.5568 9.9965 59.5568 9.40625C59.5568 8.816 59.08 8.3375 58.4918 8.3375C57.9036 8.3375 57.4268 8.816 57.4268 9.40625C57.4268 9.9965 57.9036 10.475 58.4918 10.475ZM58.4918 11.1875C57.5115 11.1875 56.7168 10.39 56.7168 9.40625C56.7168 8.42249 57.5115 7.625 58.4918 7.625C59.4721 7.625 60.2668 8.42249 60.2668 9.40625C60.2668 10.39 59.4721 11.1875 58.4918 11.1875Z"
        fill="#999999"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.6249 11.6875C28.9516 11.6875 29.2165 11.4217 29.2165 11.0938C29.2165 10.7658 28.9516 10.5 28.6249 10.5C28.2981 10.5 28.0332 10.7658 28.0332 11.0938C28.0332 11.4217 28.2981 11.6875 28.6249 11.6875Z"
        fill="white"
        stroke="#CCCCCC"
        stroke-width="0.5"
      />
      <path
        d="M2.5918 67.5H6.28747"
        stroke="#666666"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M71.8164 67.5H72.9997"
        stroke="#666666"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.5083 67.5H68.8583"
        stroke="#666666"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M54.8 35L52.5725 52"
        stroke="#E1E1E1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.7949 25.5L18.833 25.5"
        stroke="#E1E1E1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M37.8417 22.2164C38.0182 22.3103 38.0825 22.5346 37.9852 22.7175L37.6487 23.3498L38.2982 23.6953C38.4612 23.782 38.5285 23.9798 38.4614 24.1535L38.4418 24.1964C38.3446 24.3793 38.1227 24.4514 37.9461 24.3576L37.2967 24.0118L36.9289 24.7041C36.8392 24.8729 36.6432 24.9473 36.4747 24.884L36.4332 24.8652C36.2567 24.7714 36.1924 24.5471 36.2897 24.3642L36.6576 23.672L35.9366 23.289C35.7736 23.2024 35.7063 23.0046 35.7734 22.8308L35.793 22.788C35.8902 22.6051 36.1121 22.533 36.2887 22.6268L37.0096 23.01L37.346 22.3776C37.4357 22.2088 37.6317 22.1343 37.8002 22.1977L37.8417 22.2164Z"
        fill="#999999"
      />
      <path
        opacity="0.7"
        d="M48.288 15.7511C48.5316 15.9783 48.5384 16.3672 48.303 16.6196L47.4886 17.4928L48.385 18.3289C48.6135 18.542 48.6337 18.8971 48.4413 19.1486L48.4 19.1975C48.1646 19.45 47.7762 19.4704 47.5325 19.2431L46.6361 18.407L45.7455 19.3622C45.5248 19.5989 45.1696 19.6316 44.9253 19.4476L44.878 19.4078C44.6343 19.1806 44.6275 18.7917 44.863 18.5393L45.7535 17.5839L44.7583 16.6561C44.5298 16.443 44.5096 16.0879 44.702 15.8364L44.7433 15.7875C44.9787 15.5351 45.3671 15.5146 45.6108 15.7419L46.606 16.6697L47.4205 15.7967C47.6412 15.56 47.9963 15.5273 48.2406 15.7114L48.288 15.7511Z"
        fill="#999999"
      />
    </svg>
  </div>
</template>

<script>

export default {
  name: 'ShEmptyIcon',
}
</script>

<style lang='less' scoped>
.empty-icon {
  margin: 50px auto 33px;
}
</style>
