<template>
  <section class="section promotion-sec hover-bubble">
    <div class="section-l">
      <sui_icon_activity_24px :size="size" />
    </div>
    <div class="section-r">
      <p 
        v-if="isSingleMall"
        class="title"
      >
        {{ language.SHEIN_KEY_PC_30662 }}{{ fullCourtMap.length > 1 ? `(${fullCourtMap.length})` : '' }}
        <a
          v-if="fullCourtMap.length == 1 && fullCourtMap[0].add"
          class="operation"
          @click="handleSupplement(fullCourtMap[0].typeId, fullCourtMap[0].tip)"
        >
          {{ language.SHEIN_KEY_PC_15215 }}
          <sui_icon_more_right_12px
            :is-rotate="locals.GB_cssRight"
            size="12px"
          />
        </a>
      </p>
      <ul
        class="full-activity-contain"
      >
        <div>
          <li
            v-for="(item, index) in fullCourtMap"
            :key="'promotion-' + tipType + '-item-' + index"
            class="promotion-slide-item"
          >
            <p class="promotion-slide-content">
              <section>
                <span 
                  class="promotion-full-tip"
                  v-html="item.tip"
                ></span>
                <div
                  v-if="processCount(index) < 100 && item.typeId == '21'"
                  class="item-content-tip__process"
                >
                  <div
                    class="item-content-tip__process-bg"
                    :style="{ width: `${processCount(index)}%` }"
                  ></div>
                </div>
              </section>
              <a
                v-if="fullCourtMap.length > 1 && item.add"
                class="operation"
                @click="handleSupplement(item.typeId, item.tip)"
              >
                {{ language.SHEIN_KEY_PC_15215 }}
                <sui_icon_more_right_12px
                  :is-rotate="locals.GB_cssRight"
                  size="12px"
                />
              </a>
            </p>
            <ClientOnly>
              <count-down-time
                v-if="item.countDownTimeStamp"
                :time-stamp="item.countDownTimeStamp"
              />
            </ClientOnly>
          </li>
        </div>
      </ul>
    </div>
  </section>
</template>

<script>
import { sui_icon_activity_24px, sui_icon_more_right_12px } from '@shein-aidc/icon-vue3'

export default {
  name: 'PromotionTopTip',
  components: {
    sui_icon_activity_24px,
    sui_icon_more_right_12px
  },
  props: {
    language: {
      type: Object,
      default: ()=>{
        return  {}
      }
    },
    locals: {
      type: Object,
      default: ()=>{
        return  {}
      }
    },
    fullCourtMap: {
      type: Array,
      default: ()=>{
        return  []
      }
    },
    tipType: {
      type: String,
      default: 'slide'
    },
    isSingleMall: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: '18px'
    }
  },
  methods: {
    handleSupplement: function(typeId, tip){
      this.$emit('handle-supplement', typeId, tip)
    },
    processCount (index) {
      const proceeCount = this.fullCourtMap?.[index].processCount
      return proceeCount
    },
  },
  emits: ['handle-supplement']
}
</script>
