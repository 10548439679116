<template>
  <div
    v-if="fullCourtMap.length > 0"
    :class="[
      rootClassName,
      showPosition,
      {
        'hover-bubble': showPromotionBubble,
      }
    ]"
  >
    <!-- 促销全场活动：满折、满减、返券 -->
    <!-- PS:根节点层级的注释会影响测试环境打包时, 父组件透传属性如class之类 -->
    <section
      class="section promotion-sec new-promotion-sec"
      :class="{'mall-promotion':!isSingleMall, 'promotion-top': showPosition == 'top'}"
      @mouseenter="handlePromotionBubble(true)"
      @mouseleave="handlePromotionBubble(false)"
    >
      <div
        class="section-l"
        :class="{ 'section-l__center': isSingleTips }"
      >
        <!-- Icon组件不支持响应式name -->
        <span style="width: 18px">
          <component
            :is="fullPromotionIconName"
            :size="showPosition == 'top' ? '18px' : '16px'" 
          />
        </span>
      </div>
      <div
        class="section-r"
      >
        <p
          v-if="isSingleMall && !isSingleTips"
          class="title"
        >
          {{ language.SHEIN_KEY_PC_30662 }}{{ fullCourtMap.length > 1 ? `(${fullCourtMap.length})` : '' }}
          <a
            v-if="fullCourtMap.length == 1 && fullCourtMap[0].add"
            class="operation"
            tabindex="0"
            role="button"
            @keydown.stop.enter="handleSupplement(fullCourtMap[0].typeId, fullCourtMap[0].tip)"
            @click="handleSupplement(fullCourtMap[0].typeId, fullCourtMap[0].tip)"
          >
            {{ language.SHEIN_KEY_PC_15215 }}
            <sui_icon_more_right_12px
              :is-rotate="locals.GB_cssRight"
              size="12px"
            />
          </a>
        </p>
        <swiper-container
          ref="swiperContainer"
          init="false"
          class="full-activity-contain"
          :class="['j-swiper-container-promotion-'+mallCode]"
        >
          <swiper-slide
            v-for="(item, index) in fullCourtMap"
            :key="'promotion-slide-item-' + index"
            class="promotion-slide-item"
            :class="`j-promotion-header-${item.promotion_id}`"
          >
            <div
              class="promotion-slide-content"
              :style="{ alignItems: isSingleTips ? 'center' : 'initial' }"
            >
              <section
                :class="{ 'promotion-slide-content__section': isSingleTips }"
              >
                <div class="promotion-full-tip">
                  <span
                    v-if="isShowNewUserTag(item)"
                    class="promotion-full-tip__tag"
                  >
                    {{ language.SHEIN_KEY_PC_30238 }}
                  </span>
                  <span
                    class="promotion-full-tip__text"
                    v-html="item.tip"
                  ></span>
                </div>
                <div
                  v-if="processCount(item) < 100 && item.typeId == '21' && isSingleTips"
                  class="item-content-tip__process"
                >
                  <div
                    class="item-content-tip__process-bg"
                    :style="{ width: `${processCount(item)}%` }"
                  ></div>
                </div>
              </section>

              <a
                v-if="((isSingleMall && fullCourtMap.length > 1) || isSingleTips || (!isSingleMall && fullCourtMap.length == 1)) && item.add"
                class="operation"
                tabindex="0"
                role="button"
                @keydown.stop.enter="handleSupplement(item.typeId, item.tip)"
                @click="handleSupplement(item.typeId, item.tip)"
              >
                {{ language.SHEIN_KEY_PC_15215 }}
                <sui_icon_more_right_12px
                  :is-rotate="locals.GB_cssRight"
                  size="12px"
                />
              </a>
              <sui_icon_more_24px
                v-else-if="!isSingleMall && fullCourtMap.length > 1"
                :size="showPosition == 'top' ? '18px' : '16px'"
              />
            </div>
            <div
              v-if="processCount(item) < 100 && item.typeId == '21' && !isSingleTips"
              class="item-content-tip__process"
            >
              <div
                class="item-content-tip__process-bg"
                :style="{ width: `${processCount(item)}%` }"
              ></div>
            </div>
            <ClientOnly>
              <count-down-time
                v-if="item.countDownTimeStamp"
                :time-stamp="item.countDownTimeStamp"
                show-type="inline-promotion"
                :show-end-tips="true"
              />
            </ClientOnly>
          </swiper-slide>
        </swiper-container>
      </div>

      <!-- hover气泡 -->
      <promotion-top-tip
        v-if="showPromotionBubble && fullCourtMap.length > 1"
        :language="language"
        :full-court-map="fullCourtBubbleMap"
        :is-single-mall="isSingleMall"
        tip-type="bubble"
        :size="showPosition == 'top' ? '18px' : '16px'"
        :locals="locals"
        @handle-supplement="handleSupplement"
      />
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import { Autoplay } from 'swiper/modules'

import promotionTopTip from './promotionTopTip.vue'
import { isNewUserReturnCoupon } from '../../utils/index'
import { sui_icon_activity_24px, sui_icon_more_right_12px, sui_icon_selected_24px, sui_icon_couponbundle_18px, sui_icon_coupon_discount_24px, sui_icon_more_24px } from '@shein-aidc/icon-vue3'

import { daEventCenter } from '../../../../services/eventCenter'
daEventCenter.addSubscriber({ modulecode: '1-7-5' })

export default {
  name: 'FullPromotionInfoTip',
  components: {
    promotionTopTip,
    sui_icon_activity_24px,
    sui_icon_more_right_12px,
    sui_icon_selected_24px,
    sui_icon_couponbundle_18px,
    sui_icon_coupon_discount_24px,
    sui_icon_more_24px
  },
  props: {
    mallCode: {
      type: String,
      default: ''
    },
    // 是否单mall 单mall展示在顶部位置，多mall展示在mall下
    isSingleMall: {
      type: Boolean,
      default: true
    },
    // 是否只有一个提示
    isSingleTips: {
      type: Boolean,
      default: true
    },
    rootClassName: {
      type: String,
      default: ''
    },
    rootStyle: {
      type: Object,
      default(){
        return {}
      }
    },
    // isSingleMall 在代码逻辑上不足以判断组件展示位置, 因此增加prop入参
    showPosition: {
      type: String,
      default: 'mall' // 默认值为mall, 展示在顶部时为top
    },
    showBubbleFn: {
      type: Function,
      default: () => {}
    }
  },
  data(){
    return {
      navSwiper: null,
      fullCourtBubbleMap: [],
      isFullProcee: false,
      showPromotionBubble: false,     // promotion hover 气泡
    }
  },
  computed: {
    ...mapState(['locals', 'language', 'integrityPromotionInfo', 'ABTParams']),
    ...mapGetters(['cartPromotionMap', 'mallFullCourtMap']),
    fullSubtractAdd(){
      return this.fullCourtMap.find(item => item.typeId == 14)?.add
    },
    returnCouponAdd(){
      return this.fullCourtMap.find(item => item.typeId == 21)?.add
    },
    fullDiscountAllAdd(){
      return this.fullCourtMap.find(item => item.typeId == 26)?.add
    },
    /**
     * priority:优先级 1、倒计时活动加需要凑单-》2、需要凑单 -》3、倒计时-》4、普通
     * 包含活动：全场满减、 全场满折、全场返券
     */
    fullCourtMap(){
      return this.mallFullCourtMap[this.mallCode] || []
    },
    fullPromotionIconName() {
      const { couponNum, typeId } = this.fullCourtMap[0]
      return this.fullCourtMap.length > 1 
        ? 'sui_icon_activity_24px'
        : this.isFullProcee 
          ? 'sui_icon_selected_24px'
          : (typeId == 21 && couponNum > 1 ? 'sui_icon_couponbundle_18px' : 'sui_icon_coupon_discount_24px')
    }
  },
  watch: {
    fullCourtMap: {
      handler (n) {
        if(n?.length > 1){
          if(typeof window == 'undefined') return
          this.$nextTick(()=>{
            if(this.navSwiper){
              this.resizeSwiper()
            } else {
              this.initPromtionSwiper()
            }
          })
        }
        if (n?.length > 0) {
          this.setIsFullProcess(n)
        }
      },
      immediate: true
    },
    fullSubtractAdd (n, o) {
      if (n && n != o) {
        this.exposePromotionAdd(14)
      }
    },
    returnCouponAdd: {
      handler () {
        if(typeof window == 'undefined') return
        this.exposePromotionAdd(21)
      },
      immediate: true
    },
    fullDiscountAllAdd (n, o) {
      if (n && n != o) {
        this.exposePromotionAdd(26)
      }
    },
  },
  mounted() {
    if(this.fullCourtMap.length > 1){
      this.$nextTick(()=>{
        this.initPromtionSwiper()
        setTimeout(()=>{
          this.resize()
        }, 1000)
      })
    }
  },
  methods: {
    ...mapMutations(['updateAddForCoupon']),
    processCount (item) {
      const { processCount = 0 } = item
      return processCount
    },
    setIsFullProcess (data) {
      let isFullProcee = false
      data.forEach(item => {
        if (item.processCount >= 100 && item.typeId == 21) {
          isFullProcee = true
        }
      })
      this.isFullProcee = isFullProcee
    },
    resize(){
      if(this.navSwiper){
        this.resizeSwiper()
      } else {
        this.initPromtionSwiper()
      }
    },
    exposePromotionAdd(typeId){
      const item = this.fullCourtMap.find(item => item.typeId == typeId) || {}
      let promotion_id = item?.promotion_id
      promotion_id && daEventCenter.triggerNotice({
        daId: '1-7-5-1',
        extraData: {
          typeId: typeId,
          isFullPromotion: this.cartPromotionMap[promotion_id]?.isFullPromotion,
          newuser_label: isNewUserReturnCoupon(item) ? 1 : 0,
          is_member_gift: 0,
        }
      })
    },
    initPromtionSwiper () {
      this.resizeSwiper()

      const config = {
        modules: [Autoplay],
        slidesPerView: 'auto',
        direction: 'vertical',
        allowTouchMove: false,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        observer: true,
        observeParents: true,
      }
      Object.assign(this.$refs.swiperContainer, config)
      this.$refs.swiperContainer?.initialize?.()
      this.navSwiper = this.$refs.swiperContainer?.swiper
    },
    /**
     * 异步修改 dom 后重设 Swiper 高度
     */
    resizeSwiper () {
      let maxSliderHeight = 0
      const sliders = document.querySelectorAll(`.j-swiper-container-promotion-${this.mallCode} .promotion-slide-item`)
      sliders.forEach(item => {
        const promotionContentHeight = item.querySelector('.promotion-slide-content')?.getBoundingClientRect().height || 0
        const $countDownEl = item.querySelector('.c-count-down-time')
        let countDownHeight = 0
        let countDownMarginTop = 0
        if($countDownEl) {
          let computedStyle = window.getComputedStyle($countDownEl)
          let displayValue = computedStyle.getPropertyValue('display')
          if (displayValue !== 'none') {
            countDownHeight = $countDownEl.getBoundingClientRect().height || 0
            countDownMarginTop = parseInt(computedStyle.getPropertyValue('margin-top'), 10) || 0
          }
        }

        let processHeight = 0
        if(!this.isSingleTips) { // not single tip, process is outside of promotion content
          const processEle = item.querySelector('.item-content-tip__process')
          if(processEle) {
            let computedStyle = window.getComputedStyle(processEle)
            processHeight = (processEle.getBoundingClientRect().height || 0) + (parseInt(computedStyle.getPropertyValue('margin-top'), 10) || 0)
          }
        }

        const computedHeight = promotionContentHeight + countDownHeight + countDownMarginTop + processHeight

        if (computedHeight > maxSliderHeight) maxSliderHeight = computedHeight
      })

      let swiperContainerPromotionDom = document.querySelector(`.j-swiper-container-promotion-${this.mallCode}`)
      if(swiperContainerPromotionDom) {
        swiperContainerPromotionDom.style.height = `${maxSliderHeight}px`
      }
    },
    handlePromotionBubble (showBubble) {
      if(showBubble && this.showPosition != 'top') return // 临时修复mall头中展示气泡导致的展示位置错位问题
      if (this.navSwiper) {
        if (showBubble) {
          this.navSwiper.autoplay?.stop?.()
          this.fullCourtBubbleMap = this.arrStep(this.fullCourtMap, this.navSwiper.activeIndex)
        } else {
          this.navSwiper.autoplay?.start?.()
        }
      }
      this.showPromotionBubble = showBubble
      this.showBubbleFn(showBubble)
    },
    arrStep (arr, step) {
      if (step >= arr.length) return step
      const arrClone = [].concat(arr)
      const stepItems = arrClone.splice(0, step)
      return arrClone.concat(stepItems)
    },
    handleSupplement (addType) {
      const item = this.fullCourtMap.find(item => item.typeId == addType) || {}
      let promotion_id = item?.promotion_id
      daEventCenter.triggerNotice({
        daId: '1-7-5-2',
        extraData: {
          typeId: addType,
          isFullPromotion: this.cartPromotionMap[promotion_id]?.isFullPromotion,
          newuser_label: isNewUserReturnCoupon(item) ? 1 : 0,
          is_member_gift: 0,
        }
      })

      if([14, 21, 26].includes(addType)) {
        this.updateAddForCoupon({ 
          show: true, 
          type: 'AddOnV2', // V2版本走重构后的弹窗
          promotion: {
            promotion_id,
            typeId: addType,
          },
        })
        return
      }
    },
    // 是否展示新客返券标签
    isShowNewUserTag(item) {
      return this.ABTParams?.orderCouponNewUser?.param?.orderCoupon_newUser === 'new' && isNewUserReturnCoupon(item)
    }
  }
}
</script>
<style lang="less">
.operation{
  &:hover{
    text-decoration: none;
  }
}
.promotion-full-tip{
  .promotion-full-tip__tag {
    display: inline-block;
    padding: 0 4px;
    margin-right: 4px;
    max-width: 86px;
    border-radius: 2px;
    background: linear-gradient(93deg, #FF858F -10.15%, #FF6A76 -10.14%, #FF3351 33.79%, #FF3351 75.48%, #EF2649 113.06%), linear-gradient(90deg, #FF858F 0%, #FF3351 100%), linear-gradient(57deg, #FF63C2 -35.79%, #FF4142 41.52%, #FFFC33 108.14%);
    font-size: 10px;
    color: @sui_color_white;
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .promotion-full-tip__text {
    em{
      color: @sui_color_welfare_dark;
    } 
  }
}
.section{
  position: relative;
  flex: 1;
  padding: 12px;
  .flexbox();
  .margin-r(12px);
  word-break: break-word;
  max-width: 100%;
  .section-l{
    width: 18px;
    .margin-r(8px);
    &__center {
      display: flex;
      align-items: center;
    }
  }
  &.mall-promotion{
    margin-right: 0;
    padding: 12px 12px 12px 16px;
  }
  .section-r{
    flex: 1;
    overflow: hidden;
    .operation {
      white-space: nowrap;
      color: #222222;
      font-weight: bold;
      font-size: 12px;
      cursor: pointer;
      /* stylelint-disable-next-line selector-max-specificity */
      &:hover{
        text-decoration: none;
      }
    }
  }
  .title{
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
    line-height: 25px;
    .flexbox();
    .space-between();
  }
  &:last-child{
    .margin-r(0);
  }
  &.shipping-sec{
    background:@sui_color_safety_bg;

    .subtitle {
      line-height: 20px;
      color: #666;
      font-size: 13px;
      margin-top: 4px;
      /* stylelint-disable-next-line selector-max-specificity */
      .subtitle-tip {
        vertical-align: middle;
      }
    }
  }
  &.promotion-sec{
    background:@sui_color_promo_bg;
  }
}
.hover-bubble{
  position: absolute;
  .left(0px);
  top: 0px;
  background: #FFF4EB;
  z-index: @zindex-hover;
  box-shadow: 0px 2px 12px 0px rgba(111, 50, 0, 0.12);
  width: 100%;
  .full-activity-contain{
    width: calc(~"100% + 1px");
    .promotion-slide-item{
      margin-top: 24px;
      /* stylelint-disable-next-line selector-max-specificity */
      &:first-child{
        margin-top: 0px;
      }
    }
  }
}
.full-activity-contain{
  .promotion-slide-item{
    position: relative;
    font-size: 13px;
    line-height: 14px;
    span{
      vertical-align:middle;
    }
    .operation{
      white-space: nowrap;
      display: inline-block;
      cursor: pointer;
      /* stylelint-disable-next-line selector-max-specificity */
      &:hover{
        text-decoration: none;
      }
    }
    .cart__down-time{
      font-weight: normal;
      margin-top: 8px;
      font-size: 12px;
      color: @sui_color_highlight;
      /* stylelint-disable-next-line selector-max-specificity */
      .time-content{
        font-weight: normal;
        vertical-align: baseline;
        color: @sui_color_highlight;
        /* stylelint-disable-next-line selector-max-specificity */
        span{
          display: inline;
          font-weight: normal;
          color: @sui_color_highlight;
        }
      }
    }
    .promotion-slide-content{
      .flexbox();
      .space-between();
      span{
        flex: 1;
      }
      /* stylelint-disable-next-line selector-max-specificity */
      .promotion-slide-content__section {
        max-width: 100%;
        .margin-r(12px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      /* stylelint-disable-next-line selector-max-specificity */
      section:not(.promotion-slide-content__section) {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
      }
    }
    .item-content-tip__process {
      width: 100%;
      height: 3px;
      background-color: @sui_color_gray_light2;
      border-radius: 2px;
      margin-top: 4px;
    }
    .item-content-tip__process-bg {
      height: 3px;
      background-color: @sui_color_main;
      border-radius: 2px;
      transition: width .5s linear;
    }
  }
}

</style>

<style lang="less" scoped>
div.section.top {
  padding: 0;
  &.hover-bubble {
    overflow: unset;
  }
}
</style>
