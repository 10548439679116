export const UN_PAID_ORDER_CACHE_KEY = 'cart_un_paid_order'
export const CANCEL_ORDER_CACHE_KEY = 'cart_cancel_order'


/**
 * 设置缓存
 */
export const setCache = (order, key) => {
  let cache = []
  try {
    const data = JSON.parse(localStorage.getItem(key))
    cache = Array.isArray(data) ? data : []
  } catch {
    //
  }
  if (order) {
    cache.push(order)
    localStorage.setItem(key, JSON.stringify([...new Set(cache)]))
  }
}

/**
 * 获取缓存
 */
export const getCache = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key)) || []
  } catch {
    return []
  }
}

/**
 * 删除缓存
 */
export const delCache = (order, key) => {
  if (order) {
    const orders = getCache(key)
    localStorage.setItem(key, JSON.stringify([...new Set(orders.filter(item => item !== order))]))
  }
}
