import { ref, computed } from 'vue'
import { getCancelOrderInfo } from 'public/src/pages/cart_new/utils/fetcher.js'
import { useStore } from 'vuex'
import { getCache, UN_PAID_ORDER_CACHE_KEY, CANCEL_ORDER_CACHE_KEY, setCache } from './cache.js'
import { getLangMap } from '@shein-aidc/bs-cart-item-laptop'
import { getLanguageApi } from 'public/src/pages/cart_new/utils/fetcher.js'

export const useCancelOrderTip = ({ language }) => {
  // data
  const data = ref({})
  const itemLanguage = ref({})
  const isShow = ref(false)
  // store
  const store = useStore()

  // computed
  const isShowIncentive = computed(() => Object.keys(data.value?.lureInfo ?? {}).length > 0)
  const popupInfo = computed(() => data.value?.popupInfo ?? {})
  const goodsImg = computed(() => data.value?.goodsImgList ?? [])
  const goodsNum = computed(() => +data.value?.goodsNum)
  const incentiveIcon = computed(() => data.value.lureInfo.prefixIcon)
  const incentiveText = computed(() => data.value.lureInfo.text)
  const titleText = computed(() => data.value.canceledOrderTip)
  const btnText = computed(() => language.value.SHEIN_KEY_PC_19825)
  const isPlatform = computed(() => store.state.isPlatform)

  const getLanguage = () => {
    getLanguageApi(Object.values(getLangMap())).then(data => {
      if (data.code === '0') {
        itemLanguage.value = data.info?.result ?? {}
      }
    })
  }

  const getData = () => {
    return getCancelOrderInfo({
      cachedOrderIdList: [
        ...getCache(UN_PAID_ORDER_CACHE_KEY),
        ...getCache(CANCEL_ORDER_CACHE_KEY),
      ]
    }).then(res => {
      if (res?.code == 0 && res.info?.type == 1 && res.info?.popupInfo?.canceledProductList?.length > 0) {
        // 追加 index 方便埋点
        res.info?.popupInfo?.canceledProductList?.forEach((item, index) => {
          item.index = index
          item.id = `${item.goodsId}_${item.skuCode}_${item.mallCode}`
        })
        data.value = res.info
        isShow.value = true
        setCache(res.info.billno, CANCEL_ORDER_CACHE_KEY)
      }
    })
  }

  const asyncUpdateCartInfo = (cartInfo) => {
    store.dispatch('asyncUpdateCartInfo', { cartInfo: cartInfo.info })
  }

  const toggleShow = () => {
    isShow.value = !isShow.value
  }

  return {
    // variables
    data,
    itemLanguage,
    popupInfo,
    isShow,
    isShowIncentive,
    goodsImg,
    goodsNum,
    titleText,
    btnText,
    incentiveIcon,
    incentiveText,
    isPlatform,

    // methods
    getData,
    getLanguage,
    asyncUpdateCartInfo,
    toggleShow,
  }
}
