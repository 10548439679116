<template>
  <div class="img-stack-gallery">
    <div
      v-for="(item, i) in showImgs"
      :key="i"
      class="img-container"
    >
      <img
        :key="i"
        :src="item.goods_img"
        :style="{
          'z-index': 5 - i,
          [GB_cssRight ? 'margin-right' : 'margin-left']: `-${getLeftMargin(i)}px`,
          'width': `${getWidth(i)}px`,
          'height': `${getWidth(i)}px`,
        }"
      />
      <div
        v-if="count && i == 0"
        class="img-subscript"
      >
        {{ count }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineExpose, computed } from 'vue'

const { GB_cssRight } = gbCommonInfo
const getRate = (i) => (10 - 1.6 * i) / 10

const props = defineProps({
  imgList: {
    type: Array,
    default: () => ([]),
  },
  count: {
    type: [String, Number],
    default: 0
  }
})

const showImgs = computed(() => {
  return props.imgList?.slice(0, 3)
})
function getWidth(i) {
  return 40  - i * 4
}
function getLeftMargin(i) {
  if (i == 0) return 0
  const w = getWidth(i)
  return w - 8 * getRate(i)
}

defineExpose({
  showImgs,
  getWidth,
  getLeftMargin,
})
</script>

<style lang="less" scoped>
.img-stack-gallery {
  display: flex;
  align-items: center;
  position: relative;
  img {
    width: 36px;
    height: 36px;
    border-radius: 6px;
    border: 0.5px solid #FFEDDF;
    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.16);
    float: left;
    position: relative;
  }
  .img-container {
    position: relative;
    display: flex;
    justify-content: flex-end;
  }
  .img-subscript {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 10;
    padding: 0 2px;
    border-radius: 6px 0px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.70);
    font-size: 8px;
  }
}
</style>
