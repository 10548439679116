<template>
  <recommend v-if="renderComponents.includes('recommend')" />
</template>

<script>
import { defineAsyncComponent } from 'vue'
const { cartlists } = window.gbCartSsrData
const render_components = ['recommend']

export default {
  name: 'CartRecommendContainer',
  // 组件注册
  components: {
    recommend: defineAsyncComponent(() => import('./components/recommend.vue')),
  },
  data(){
    return {
      renderComponents: [],
    }
  },
  mounted () {
    if(cartlists.carts.length <= 6) {
      this.setRenderComponents()
    } else {
      if (window.requestIdleCallback) {
        window.requestIdleCallback(async () => {
          this.setRenderComponents()
        })
      } else {
        this.setRenderComponents()
      }
    }
  },

  // 方法实现
  methods: {
    // 分块渲染
    setRenderComponents() {
      let renderComponentsLength = this.renderComponents.length
      if (renderComponentsLength >= 1) {
        return
      }
      requestAnimationFrame(() => {
        this.renderComponents.push(render_components[renderComponentsLength])
        this.setRenderComponents()
      })
    },
  },
}
</script>
<style lang="less">
.cart-recommend-list{
  margin: 0 auto;
  padding-top: 20px;
  .recommend-title{
    font-size: 24px;
    font-family: Arial-Black, Arial /* rw: Adieu*/;
    font-weight: 900;
    color: #222222;
    line-height: 33px;
    border: none;
    padding: 0 0 24px;
    margin: 0;
  }
  .c-recommend-goodsd{
    .swiper-container__normal{
      margin: 0;
    }
    .recommend-next,.recommend-prev{
      width: 32px;
      height: 32px;
      background: rgba(255, 255, 255, 0.94);
      box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 8%);
      border-radius: 18px;
      font-size: 12px;
      text-align: center;
      vertical-align: unset;
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-weight: bold;
      color: #000;
    }
  }
}

</style>
