import { template } from '@shein/common-function'
import { is0ThresholdCoupon } from 'public/src/pages/cart_new/utils/coupon.js'


const couponAddon = {
  // 凑单引导选券
  couponAddon(state) {
    let targetCoupon = null
    if(state.couponInfo?.usableCouponList?.length < 1 && state.couponInfo?.disabledCouponList?.length > 0) {
      let targetCoupons =  state.couponInfo.disabledCouponList.filter(i => i.apply_for == 1 && [1, 5].includes(Number(i.coupon_type_id)) && i.coupon_dimension == 1)
      if(targetCoupons.length > 0){
        targetCoupons.sort((prev, next) => {
          // 先按金额排序
          if(Number(prev.need_price) < Number(next.need_price)) return -1
          else if(Number(prev.need_price) == Number(next.need_price)) {
            // 再按类型排序(应付价折扣券优先)
            if(prev.coupon_type_id > next.coupon_type_id) return -1
            else if(prev.coupon_type_id == next.coupon_type_id) {
              // 最后按券面额排序, 一码多档券取最低档面额, 其他券面额字段长度为1
              if(Number(prev.other_coupon_rule?.[0]?.price.price) >= Number(next.other_coupon_rule?.[0]?.price.price)) return -1
              else return 1
            } else return 1
          } else return 1
        })
        targetCoupon = targetCoupons[0]
      }
    }
    return targetCoupon
  },
  // 券权益
  couponDiscount(state) {
    let savePrice = null
    let coupon = {}
    if(state.cartInfo?.coupon_infos?.length > 0 && Number(state.cartInfo?.coupon_discount_price?.amount) > 0) {
      savePrice = state.cartInfo?.coupon_discount_price?.amountWithSymbol
    } else if (state.couponInfo?.usableCouponList?.length > 0) {
      let maxDiscount = -999
      state.couponInfo.usableCouponList.filter(item => ![ '5', '6', '9' ].includes(item.apply_for)).forEach(i => {
        if(Number(i.real_discount) > maxDiscount) {
          maxDiscount = Number(i.real_discount)
          savePrice = i?.realDiscountPrice?.amountWithSymbol || ''
          coupon = i
        }
      })
    }
    return savePrice ? { text: savePrice, coupon } : null
  },
  couponProcessCount(state, getters) {
    if(!getters.couponAddon) return null
    const is_0ThresholdCoupon = is0ThresholdCoupon(getters.couponAddon)
    if (is_0ThresholdCoupon) return 0
    let targetPrice = Number(getters.couponAddon.other_coupon_rule[0].min_order.price)
    let process = (targetPrice - Number(getters.couponAddon.need_price)) / targetPrice * 100
    if(process < 0) process = 0
    else if(process > 100) process = 100
    return process
  },
  couponAddonTip(state, getters) {
    if(getters.couponAddon) {
      let needPrice = `<em>${getters.couponAddon?.needPrice?.amountWithSymbol}</em>`
      let targetPrice = getters.couponAddon?.coupon_type_id == '1' ? getters.couponAddon?.other_coupon_rule[0]?.price.priceSymbol : (Number(getters.couponAddon?.other_coupon_rule[0]?.price.price) * 100 / 100) + '%'
      const is_0ThresholdCoupon = is0ThresholdCoupon(getters.couponAddon)
      return is_0ThresholdCoupon
        ? template(targetPrice, state.language.SHEIN_KEY_PC_30899)
        : template(needPrice, targetPrice, state.language.SHEIN_KEY_PC_26081)
    } else if(getters.couponDiscount) {
      return template(`<em>${getters.couponDiscount?.text}</em>`, state.language.SHEIN_KEY_PC_26084)
    } else return null
  },
  couponAddonShowCountDown(state, getters) {
    if(!getters.couponAddon) return false
    let endTimestamp =  getters.couponAddon?.end_date
    let diffTime = endTimestamp * 1000 - Date.now()
    if(diffTime <= 0) return false
    let diffDays = Math.floor(diffTime / (24 * 3600 * 1000))
    if(diffDays >= 3) return false
    return true
  },
  // 全场优惠券凑单头部场景状态 1. 凑单引导场景couponAddon 2. 券权益场景couponDiscount
  CouponAddonStatus(state, getters) {
    if(getters.couponAddon) {
      return 'couponAddon'
    } else if(getters.couponDiscount) {
      return 'couponDiscount'
    } else {
      return ''
    }
  }
}

export default couponAddon
