import { ref, nextTick } from 'vue'
import { DELETE_TIPS_TYPES, TITLE_LANGUAGE_KEYS, DISCOUNT_INFO_LIST, TEXT_LANGUAGE_KEYS, CONFIRM_BTN_LANGUAGE_KEYS, DELETE_BTN_LANGUAGE_KEYS } from '../constant'

const isShowDeleteTips = ref(false)
const deleteTipsType = ref(0)
const deleteTipsTitle = ref('')
const deleteTipsText = ref('')
const discountInfoList = ref([])
const confirmBtnText = ref('')
const deleteBtnText = ref('')
const singleDiscountAmount = ref(0)
let extraData = {}
let _onUncheck = null
let _onDelete = null
let _onMoveToWishList = null
let _onDeleteAndFindSimilar = null
let _onClose = null

export default function useDeleteTips() {
  const getExtraData = (item = {}, deleteTipsType) => {
    if (item.single_discount_price?.amount > 0) {
      extraData.actual_point = 1
    } else if (deleteTipsType === DELETE_TIPS_TYPES.SIMILAR) {
      extraData.actual_point = 2
    } else {
      extraData.actual_point = '-'
    }

    if ([DELETE_TIPS_TYPES.BATCH_NORMAL, DELETE_TIPS_TYPES.BATCH_SIMILAR].includes(deleteTipsType)) {
      extraData.type_id = 'batch_delete'
    } else {
      extraData.type_id = (item.promotionTypeIds || []).join(',')
    }

    extraData.save_money = item.single_discount_price?.usdAmountWithSymbol || '-'

    let available_point = []
    if (item.single_discount_price?.amount > 0) {
      available_point.push(1)
    }
    if (deleteTipsType === DELETE_TIPS_TYPES.SIMILAR) {
      available_point.push(2)
    }
    extraData.available_point = available_point.join(',') || '-'
    
    if ([DELETE_TIPS_TYPES.PROMTOTECUT_CHECKED_NO_DISCOUNT, DELETE_TIPS_TYPES.PROMTOTECUT_CHECKED_HAS_DISCOUNT].includes(deleteTipsType) || (deleteTipsType === 0 && item.is_checked)) {
      extraData.sence = 1
    } else if (DELETE_TIPS_TYPES.PROMTOTECUT_UNCHECKED === deleteTipsType || (deleteTipsType === 0 && !item.is_checked)) {
      extraData.sence = 2
    } else if (DELETE_TIPS_TYPES.SIMILAR === deleteTipsType) {
      extraData.sence = 3
    } else if ([DELETE_TIPS_TYPES.BATCH_NORMAL, DELETE_TIPS_TYPES.BATCH_SIMILAR].includes(deleteTipsType)) {
      extraData.sence = 4
    }
    return extraData
  }
  /**
   * 获取删除提示类型
   * @param {*} cartAbtInfo abt
   * @param {*} item 商品行信息
   * @param {*} isBatch 是否批量处理
   * @returns DELETE_TIPS_TYPES
   */
  const getDeleteTipsType = (cartAbtInfo = {}, item = {}, isBatch = false) => {
    const { ShopLineRetention = {} } = cartAbtInfo
    const {
      ControlBatchDelete = '',
      ControlRetentionShow = 'on,promotecut'
    } = ShopLineRetention.param || {}
    const [state, type] = ControlRetentionShow.split(',')
    const [stateOfBatch, typeOfBatch] = ControlBatchDelete.split(',')

    const { is_checked = 0, single_discount_price = {} } = item

    singleDiscountAmount.value = single_discount_price.amountWithSymbol || ''

    if (!isBatch && state === 'on' && type === 'promotecut' && is_checked && single_discount_price.amount == 0) return DELETE_TIPS_TYPES.PROMTOTECUT_CHECKED_NO_DISCOUNT

    if (!isBatch && state === 'on' && type === 'promotecut' && is_checked && single_discount_price.amount > 0) return DELETE_TIPS_TYPES.PROMTOTECUT_CHECKED_HAS_DISCOUNT

    if (!isBatch && state === 'on' && type === 'promotecut' && !is_checked) return DELETE_TIPS_TYPES.PROMTOTECUT_UNCHECKED

    if (!isBatch && state === 'on' && type === 'similar') return DELETE_TIPS_TYPES.SIMILAR

    if (isBatch && stateOfBatch === 'on' && typeOfBatch === 'normal') return DELETE_TIPS_TYPES.BATCH_NORMAL

    if (isBatch && stateOfBatch === 'on' && typeOfBatch === 'similar') return DELETE_TIPS_TYPES.BATCH_SIMILAR

    return 0
  }
  const showDeleteTips = ({
    cartAbtInfo = {},
    language = {},
    item = {},
    isBatch = false,
    onUncheck,
    onDelete,
    onMoveToWishList,
    onDeleteAndFindSimilar,
    onClose
  } = {}) => {
    deleteTipsType.value = getDeleteTipsType(cartAbtInfo, item, isBatch)
    if (deleteTipsType.value !== 0) {
      _onUncheck = onUncheck
      _onDelete = onDelete
      _onMoveToWishList = onMoveToWishList
      _onDeleteAndFindSimilar = onDeleteAndFindSimilar
      _onClose = onClose
      deleteTipsTitle.value = language[TITLE_LANGUAGE_KEYS[deleteTipsType.value]]
      discountInfoList.value = DISCOUNT_INFO_LIST.map(item => {
        return {
          iconName: item.iconName,
          name: language[item.languageKey] || item.name,
        }
      })
      deleteTipsText.value = language[TEXT_LANGUAGE_KEYS[deleteTipsType.value]]
      confirmBtnText.value = language[CONFIRM_BTN_LANGUAGE_KEYS[deleteTipsType.value]]
      deleteBtnText.value = language[DELETE_BTN_LANGUAGE_KEYS[deleteTipsType.value]]
      extraData = {}
      getExtraData(item, deleteTipsType.value)
      daEventCenter.triggerNotice({
        daId: '1-7-3-41',
        extraData,
      })
      nextTick(() => {
        isShowDeleteTips.value = true
      })
      return true
    } else {
      return false
    }
  }
  const onClose = () => {
    isShowDeleteTips.value = false
    _onClose && _onClose()
    let closeExtraData = { ...extraData, click_type: 'close' }
    daEventCenter.triggerNotice({
      daId: '1-7-3-42',
      extraData: closeExtraData,
    })
  }
  const onClickConfirm = () => {
    let click_type = ''
    if ([DELETE_TIPS_TYPES.PROMTOTECUT_CHECKED_NO_DISCOUNT, DELETE_TIPS_TYPES.PROMTOTECUT_CHECKED_HAS_DISCOUNT].includes(deleteTipsType.value)) {
      isShowDeleteTips.value = false
      click_type = 'uncheck_and_keep_in_cart'
      _onUncheck && _onUncheck()
    }
    if ([DELETE_TIPS_TYPES.PROMTOTECUT_UNCHECKED, DELETE_TIPS_TYPES.BATCH_NORMAL, DELETE_TIPS_TYPES.BATCH_SIMILAR].includes(deleteTipsType.value)) {
      isShowDeleteTips.value = false
      click_type = 'move_to_wishlist'
      _onMoveToWishList && _onMoveToWishList()
    }
    if (DELETE_TIPS_TYPES.SIMILAR === deleteTipsType.value) {
      isShowDeleteTips.value = false
      click_type = 'remove_and_explore_more'
      _onDeleteAndFindSimilar && _onDeleteAndFindSimilar()
    }
    let confirmExtraData = { ...extraData, click_type }
    daEventCenter.triggerNotice({
      daId: '1-7-3-42',
      extraData: confirmExtraData,
    })
  }
  const onClickDelete = () => {
    let click_type = ''
    if ([DELETE_TIPS_TYPES.PROMTOTECUT_CHECKED_NO_DISCOUNT, DELETE_TIPS_TYPES.PROMTOTECUT_CHECKED_HAS_DISCOUNT, DELETE_TIPS_TYPES.PROMTOTECUT_UNCHECKED, DELETE_TIPS_TYPES.SIMILAR, DELETE_TIPS_TYPES.BATCH_NORMAL].includes(deleteTipsType.value)) {
      isShowDeleteTips.value = false
      click_type = 'remove_item'
      _onDelete && _onDelete()
    }
    if (DELETE_TIPS_TYPES.BATCH_SIMILAR === deleteTipsType.value) {
      isShowDeleteTips.value = false
      click_type = 'remove_and_explore_more'
      _onDeleteAndFindSimilar && _onDeleteAndFindSimilar()
    }
    let deleteExtraData = { ...extraData, click_type }
    daEventCenter.triggerNotice({
      daId: '1-7-3-42',
      extraData: deleteExtraData,
    })
  }
  return {
    isShowDeleteTips,
    deleteTipsType,
    deleteTipsTitle,
    discountInfoList,
    deleteTipsText,
    confirmBtnText,
    deleteBtnText,
    singleDiscountAmount,
    showDeleteTips,
    onClose,
    onClickConfirm,
    onClickDelete,
    getExtraData,
  }
}
