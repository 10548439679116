import infiniteScroll from '@sheinfe/vue-infinite-scroll'
import { upToTopInit } from 'public/src/pages/footer/up_to_top/index.js'
import { businessLibInitializeForCSR } from 'public/src/pages/common/business-lib-initialize/csr.js'
import './components/ga_cart.js'
import { initRecommend } from '../cart/init.js'

import { createApp } from './app'

const { app, store } = createApp()

businessLibInitializeForCSR(app)

app.use(infiniteScroll)

if (window.gbCartSsrData) {
  store.replaceState({ ...store.state, ...window.gbCartSsrData })
}

const vm = app.mount('.j-cart-container') 

window.cartVue = vm
const { IS_RW } = gbCommonInfo
const MIN_WIDTH = IS_RW ? 768 : 800

initRecommend({ store })
let fixTitle = function() {
  // 屏效后页头高度常量有变化, 需要更新否则会闪动
  let innerWidth = window.innerWidth
  const bodyOffsetHeight = document.body.offsetHeight
  let topTplDom = document.querySelector('.j-top-tpl')
  let topTplClientRect = topTplDom?.getBoundingClientRect() || {}

  let $placeHolder = document.querySelector('.j-shoppromo-tip-placeholder')
  let $summary = document.querySelector('.j-vue-price .c-check__cart-right')
  let bagTitleWidth = document.querySelector('.j-shopbag-body')?.getBoundingClientRect()?.width || 0
  let recommendDomTop = document.querySelector('.j-recommend-list')?.getBoundingClientRect()?.top || 0
  let summaryWidth = $summary?.offsetWidth || 0
  let summaryClientRect = $summary?.getBoundingClientRect() || {}

  // 筛选栏
  const $cartFilter = document.querySelector('.j-cart-list-filter')
  const $filterPlaceHolder = document.querySelector('.j-filter-placeholder') // 筛选栏脱离文档流时占位
  let cartFilterHeight = $cartFilter?.getBoundingClientRect()?.height || 0
  $filterPlaceHolder && ($filterPlaceHolder.style.height = cartFilterHeight + 'px')

  let summaryHeight = summaryClientRect.height || 0 // 商品明细高度
  let checkBagHeight = document.querySelector('.j-check-bag')?.getBoundingClientRect()?.height || 0 // 购物车左侧高度
  
  let hasCartItemEle = document.querySelectorAll('.j-vue-bag .j-cart-item')

  // 空购物车情况
  if(hasCartItemEle.length == 0 || !$summary || (summaryWidth > bagTitleWidth)){
    $placeHolder && ($placeHolder.style.display = 'none')
    return
  }
  
  if (checkBagHeight <= bodyOffsetHeight || innerWidth < MIN_WIDTH){ 
    topTplDom && (topTplDom.style.position = 'static')
    $placeHolder && ($placeHolder.style.display = 'none')
    $cartFilter && ($cartFilter.style.position = 'static')
    $filterPlaceHolder && ($filterPlaceHolder.style.display = 'none')
    return
  }

  if(hasCartItemEle.length && topTplDom && $summary && parseInt(summaryClientRect.top) < 0  && checkBagHeight > summaryHeight) {
    if(topTplClientRect.top <= 0 && recommendDomTop > 0 && recommendDomTop <= (summaryHeight + 30)) {
      topTplDom.style.position = 'absolute'
      topTplDom.style.top = 'auto'
      topTplDom.style.bottom = (summaryHeight - topTplClientRect.height) + 'px'
      topTplDom.style.width = bagTitleWidth + 'px'
      if($placeHolder){
        $placeHolder.style.height = topTplClientRect.height + 'px'
        $placeHolder.style.display = 'block'
      }
      if ($cartFilter && $cartFilter.classList.contains('filter-sticky')) {
        $filterPlaceHolder && ($filterPlaceHolder.style.display = 'block')
        
        $cartFilter.style.position = 'absolute'
        $cartFilter.style.top = 'auto'
        $cartFilter.style.bottom = summaryHeight - topTplClientRect.height - cartFilterHeight + (topTplClientRect.height ? 1 : 0) + 'px'
      }
    }
  } else {
    if ($cartFilter && $cartFilter.classList.contains('filter-sticky')) {
      // control_top为true/进行筛选，滚动超出购物车列表区域（取消吸顶），再反向滚动回来恢复sticky吸顶效果
      let top = $cartFilter.getAttribute('data-top')
      $cartFilter.style.position = ''
      $cartFilter.style.top = +top ? top + 'px' : 0
      $cartFilter.style.bottom = ''
    }
    $filterPlaceHolder && ($filterPlaceHolder.style.display = 'none')
    topTplDom && (topTplDom.setAttribute('style', ''))
    $placeHolder && ($placeHolder.style.display = 'none')
  }
}
// 购物车页面增加一键回到顶部
upToTopInit()

window.addEventListener('scroll', function() {
  fixTitle()
})

window.addEventListener('resize', function() {
  let topTplDom = document.querySelector('.j-top-tpl')
  if(topTplDom?.style.position == 'absolute'){
    let bagTitleWidth = document.querySelector('.j-shopbag-body')?.getBoundingClientRect()?.width || 0
    topTplDom.style.width = bagTitleWidth + 'px'
  }
})

window.scrollTo({
  top: 0,
  behavior: 'smooth'  // 可选，平滑滚动到顶部
})
