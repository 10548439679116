<template>
  <div
    v-show="showItem"
    ref="cartItemBlock"
    da-expose-code="cart-item-block"
    class="cart-table cart-table-item j-cart-item j-valid-cart-item"
    :class="[
      { 'sold-out': soldoutIds.indexOf(item.id) > -1 },
      { 'not-platform-item': !isPlatform && !showThreeStore },
      {'free-item-disable': item.isGiftItem && !item.is_checked},
      {'club-gift-disable': item.isSheinClubGiftItem && !item.is_checked},
      {'cart-table-item_max-pb': isShowInputNumberTips}
    ]"
    :sku="item.product.goods_sn"
    :data-cart-id="item.id"
  >
    <div
      v-show="!saveOrDeleted"
      class="cart-gd-name"
    >
      <img
        v-if="goodsNameBeforeLabel.icon"
        class="gd-name__before-label"
        :src="
          transformImg({
            img: goodsNameBeforeLabel.icon,
          })
        "
      />
      <a
        class="gd-name"
        :tabindex="isBatchActivePage ? -1 : 0"
        :title="item.product.goods_name"
        @click="clickGoodsTitle()"
      >
        {{ item.product.goods_name }}
      </a>
    </div>
    <ul
      v-show="!saveOrDeleted"
      class="cart-row"
      :data-goods-status="item.goodsStatus"
      :data-goods-id="item.product.goods_id"
      :data-sku-id="item.product.goods_sn"
      :data-spu-id="item.product.productRelationID"
      :data-index="index"
      :data-us-price="item.product.feSalePrice.usdAmount"
      :data-us-origin-price="item.product.feOriginalPrice.usdAmount"
      :data-attr-stock="item.attr && item.attr.stock"
      :data-mall-code="item.mall_code"
      :data-insurable="item.product.insurable"
      :da-event-expose="isBatchActivePage? '' : '1-7-3-6'"
      :da-tsp-id="blackFridayLabel?.tagId"
      :data-unuse-coupon="isShowNoCouponUse(item)"
      :data-behavior-label="getBehaviorText(item)"
      :data-goods-name-before-label-id="goodsNameBeforeLabel.labelId"
      :data-shien-club-gift-tag="showSheinClubGiftTag"
      :data-other-mark="getOtherMark(item)"
      :label-id="getLabelId"
    >
      <li
        class="cart-row-cell cart-img"
        :class="{ 'fsp-element': index < 4 }"
      >
        <div class="cart-img-gd">
          <slot></slot>
          <cart-list-item-img
            :item="item"
            :is-first-page="index < 6"
            :is-batch-active-page="isBatchActivePage"
            :hide-corner="isNewCartDiscountStyle"
            :goods-detail-url="goodsDetailUrl"
            @click-goods-list="clickGoodsList"
          />
        </div>
      </li>
      <li
        class="cart-row-cell cart-row-item cart-info"
        :class="{ 'cart-item-content__mask': item.isNewcomerItem }"
      >
        <CartItemStore
          v-if="isInlineStore"
          class="item-store"
          :item="item"
          :store-selects-info="storeSelectsInfo"
          :show-o-b-m-store="showOBMStore"
          :show-three-store="showThreeStore"
          :css-config="{
            preferredSellerWidth: 16,
            preferredSellerHeight: 16,
            storeIconFontSize: 16,
            textFontSize: 13,
            fontWeight: 'normal',
          }"
          :locals="locals"
        />
        <cart-list-attr-btn
          :item="item"
          :index="index"
          :is-batch-active-page="isBatchActivePage"
          :show-evolu-shein="showEvoluShein"
        />
        <div class="place-holder"></div>
        <CartItemPriceBlock
          :item="item"
          :index="index"
          :limit-num="itemLimitNum"
          :is-batch-active-page="isBatchActivePage"
          :show-before-coupon-price="showBeforeCouponPrice"
          :show-estimated-tag="showEstimatedTag"
          :show-discount-on-estimated="showDiscountOnEstimated"
          :show-discount-tag="showDiscountTag"
          :discount-text="discountText"
          @del-or-save-opt="delOrSaveOpt"
          @on-uncheck="onUncheck"
          @show-input-number-tips="(v) => isShowInputNumberTips = v"
        />
      </li>
    </ul>
    <div
      v-show="saveOrDeleted"
      class="cart-item__remove-tip"
      :class="{'removed': [3].indexOf(saveOrDeleted) > -1}"
    >
      <div>
        <span></span>
      </div>
    </div>
  </div>
  <div
    v-if="!isSoldOut && isNewReturnFlag && notReturnText"
    v-show="showItem"
    class="cart-item__negative-info j-cart-item__negative-info"
  >
    <GoodsNegativeInfo
      :negative-infos="[{ text: notReturnText }]"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import cartListItemImg from './cartListItemImg.vue'
import cartListAttrBtn from './newList/CartItemAttrBtn.vue'
import CartItemPriceBlock from './newList/CartItemPriceBlock.vue'
import CartItemStore from './newList/CartItemStore.vue'
import { GoodsNegativeInfo } from '@shein-aidc/bs-cart-item-laptop'
import { daEventCenter } from '../../../../services/eventCenter'
import { handleOrderOrSingleIsMeetOrOver, getDiscountText } from 'public/src/pages/cart_new/utils/index.js'
daEventCenter.addSubscriber({ modulecode: '1-7-3' })
const daEventExpose = daEventCenter.getExposeInstance()
import BehaviorLabel from 'public/src/pages/cart_new/utils/behaviorLabel.js'
import { getProductDetailUrl } from 'public/src/pages/common/utils/index.js'
import { transformImg } from '@shein/common-function'
import { isShowEstimated } from '@/public/src/pages/cart_new/components/EstimatedTag/utils.js'
import { showFilterItem } from '../../utils/cartFilterLabels.js'

const { forceHttp } = gbCommonInfo
export default {
  name: 'CartListItem',
  components: {
    GoodsNegativeInfo,
    cartListItemImg,
    cartListAttrBtn,
    CartItemPriceBlock,
    CartItemStore,
  },
  props: {
    item: {
      type: Object,
      default(){
        return {}
      }
    },
    index: {
      type: Number,
      default: 0
    },
    itemPromotionGroupInfo: {
      type: Object,
      default(){
        return {}
      }
    },
    isInlineStore: {
      type: Boolean,
      default: false,
    },
    isBatchActivePage: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      forceHttp,
      saveOrDeleted: 0,
      isShowInputNumberTips: false,
      goodsDetailUrl: '',
      isSafari: false,
    }
  },
  computed: {
    ...mapState(['locals', 'language', 'isPlatform', 'ABTParams', 'soldoutIds', 'mallShippingInfo', 'integrityPromotionInfo', 'blackFridayImgGoods', 'storeSelectsInfo', 'filterType', 'cartAbtInfo', 'goodsFilterLabels', 'targetCouponInfo']),
    ...mapGetters(['singleMallCode', 'flashSaleGroupList', 'showThreeStore', 'underPriceGroupList', 'showOBMStore', 'showThreeStore', 'switchOnEvoluSHEIN', 'selectPrice', 'isNewCartDiscountStyle', 'isNewReturnFlag', 'isSuggested']),
    // doFilter为false时, 所有item都显示, 当其为true时, 再根据filterType筛选合适的item展示
    doFilter() {
      return this.filterType != 'none'
    },
    showItem() {
      return !this.doFilter + showFilterItem(this.item, this.filterType, this.targetCouponInfo, this.goodsFilterLabels)
    },
    promotionGroupInfo(){
      let groupPromotion = this.item.product.product_promotion_info?.find(promotion => promotion.type_id == 10 || promotion.type_id == 30) // 常规闪购活动支持跨店铺or一口价
      if(groupPromotion) {
        let groupName = groupPromotion.type_id == 10 ? 'flashSaleGroupList' : 'underPriceGroupList'
        let groupPro = this.integrityPromotionInfo[groupPromotion.promotion_id] || {}
        return { ...groupPro, list: this[groupName][groupPromotion.promotion_id] || [] }
      }
      return this.itemPromotionGroupInfo
    },
    /**
     * 单品或订单限购满足数量或超过的情况
     * isOrderOrSingleMeetOrOver 是否单品或订单限购满足数量或超过，是则置灰添加按钮
     * itemLimitNum 商品行限购数量
     */
    orderOrSingleMeetOrOverResult(){
      const { result, itemLimitNum } = handleOrderOrSingleIsMeetOrOver(this.item, this.promotionGroupInfo, false, true)
      return {
        isOrderOrSingleMeetOrOver: Boolean(result),
        itemLimitNum
      }
    },
    // 商品行限购数量 0：不限购
    itemLimitNum(){
      let promotion = this.item.product.product_promotion_info?.find(item => item.type_id == 3)
      if (promotion) {
        const { promotion_id = '' } = promotion
        const promotionInfo = this.integrityPromotionInfo[promotion_id] || {}
        return promotionInfo.cart_limit?.single || 0
      } else {
        return this.orderOrSingleMeetOrOverResult.itemLimitNum
      }
    },
    showNoFreeShipping () {
      if(this.isPlatform){
        let result = 0
        let platformActivityType =  this.mallShippingInfo[`platform`]?.activity_type || ''
        result = (platformActivityType == 1 && this.item.free_activity_limit == 1) || (platformActivityType == 2 && this.item.free_policy_limit == 1) || (platformActivityType == 3 && this.item.freight_activity_limit == 1)
        if(result){
          return 1
        }
        let shopOrSelfActivityType = this.mallShippingInfo[this.item.mall_code]?.activity_type || ''
        result = (shopOrSelfActivityType == 1 && this.item.free_activity_limit == 1) || (shopOrSelfActivityType == 2 && this.item.free_policy_limit == 1) || (shopOrSelfActivityType == 3 && this.item.freight_activity_limit == 1)
        return result ? 2 : 0
      }
      let actType = this.mallShippingInfo[this.singleMallCode]?.activity_type || ''
      return (actType == 1 && this.item.free_activity_limit == 1) || ( actType == 2 && this.item.free_policy_limit == 1) || (actType == 3 && this.item.freight_activity_limit == 1) ? 2 : 0
    },
    getActivityType(){
      if(this.showNoFreeShipping){
        return this.showNoFreeShipping == 1 ? this.mallShippingInfo[`platform`]?.activity_type : this.mallShippingInfo[this.item.mall_code]?.activity_type
      }
      return ''
    },
    blackFridayLabel() {
      return this.blackFridayImgGoods[this.item.product.goods_id]?.promoLabel || {}
    },
    goodsNameBeforeLabel() {
      return this.blackFridayImgGoods[this.item?.product?.goods_id]?.cartPcStandardView?.goodsNameLabel || {}
    },
    showEvoluShein() {
      const { EVOLU_SHEIN_ID } = this.locals
      return this.switchOnEvoluSHEIN && this.item?.rules?.includes(EVOLU_SHEIN_ID)
    },
    showSheinClubGiftTag() {
      return this.item.promotionTag?.typeId === 4 && [8, 14].includes(this.item.promotionTag?.promotionLogoType)
    },
    // 是否显示券前价：在券筛选状态下且开启了abt值为beforecouponprice
    showBeforeCouponPrice() {
      return !isNaN(this.filterType) && this.selectPrice == 'beforecouponprice'
    },
    isSoldOut() {
      return this.soldoutIds && this.soldoutIds.includes(this.item.id)
    },
    // 是否展示到手价标签
    showEstimatedTag() {
      // 已失效商品/赠品/启券前价abt 不显示到手价标签
      if (this.isSoldOut || this.item.isGiftItem || this.showBeforeCouponPrice) return false
      // 默认值修改合规站点为on，非合规站点为off
      const cart_notChecked_estimate = this.cartAbtInfo.CartNotCheckedEstimate?.param?.cart_notChecked_estimate || (!this.isSuggested ? 'on' : 'off')
      if (this.item.is_checked === 0 && cart_notChecked_estimate !== 'on') return false
      return isShowEstimated(this.item)
    },
    // 是否在到手价标签上展示折扣信息：显示折扣标签 && 开启折扣标签新样式abt && 当前商品行有折扣信息
    showDiscountOnEstimated() {
      return this.showEstimatedTag && this.isNewCartDiscountStyle && this.maxDiscount != 0
    },
    // 是否展示折扣标签：abt开启 && 当前商品行无到手价标签 && 当前商品行有折扣信息
    showDiscountTag() {
      return this.isNewCartDiscountStyle && !this.showEstimatedTag && this.maxDiscount != 0
    },
    notReturnText() {
      if (typeof window === 'undefined') return ''
      const { feRefundAble, return_flag, isCustomGood } = this.item
      if (gbCommonInfo.SiteUID === 'de') {
        if (isCustomGood) return this.language.SHEIN_KEY_PC_32381
        if (return_flag) {
          if (feRefundAble === 1) return this.language.SHEIN_KEY_PC_32383
          if (feRefundAble === 0) return this.language.SHEIN_KEY_PC_32382
        }
        return ''
      } else {
        if (isCustomGood) return this.language.SHEIN_KEY_PC_32381
        if (return_flag === 1) return this.language.SHEIN_KEY_PC_32379
        if (return_flag === 2) return this.language.SHEIN_KEY_PC_32380
        if (return_flag === 3) return this.language.SHEIN_KEY_PC_32380
        return ''
      }
    },
    // 当前商品行最大折扣信息
    maxDiscount() {
      if (this.item.isGiftItem) return 0
      const { unit_discount = 0 } = this.item?.product
      return Number(unit_discount)
      // const { unit_discount = 0, sale_discount = 0 } = this.item?.product
      // return Math.max(Number(unit_discount), Number(sale_discount))
    },
    discountText() {
      const { lang, GB_cssRight } = this.locals
      return getDiscountText(lang, GB_cssRight, this.maxDiscount)
    },
    getLabelId() {
      return !isNaN(this.filterType)
        ? this.goodsFilterLabels.find(i => this.filterType == i.id)?.couponType
        : this.filterType
    },
  },
  mounted() {
    this.initExpose()
    if (navigator && navigator.userAgent) {
      this.isSafari = navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome') ? true : false
      if (this.isSafari) {
        this.getGoodsDetailUrl()
      }
    }
  },
  methods: {
    /**
     * @description 不可参与优惠券提示
        1、自营商品
        2、have_tag为1命中
        3、abt返回on
     * @param {*} item 购物车商品属性
     */
    isShowNoCouponUse (item) {
      let flag = 'unused_coupon_tip_0'
      if (item?.store_type != 2) return flag
      if (item?.product?.have_tag == 1) {
        flag = `unused_coupon_tip_${
          this.ABTParams?.['Cartnoteligibleforcoupons']?.param == 'on'
            ? 1
            : 2
        }`
      }
      return flag
    },
    getBehaviorText(item) {
      const label = this.blackFridayImgGoods[item?.product?.goods_id]?.showBehaviorLabel
      if (!label) return ''
      return BehaviorLabel.getSaText(label, this.language)
    },
    async getGoodsDetailUrl() {
      const {
        item: {
          mall_code,
          product: { goods_img, goods_url_name, goods_id, cat_id },
        }
      } = this
      const url = await getProductDetailUrl({
        imgSrc: goods_img,
        goods_url_name,
        goods_id,
        cat_id,
        mall_code,
      })
      this.goodsDetailUrl = url
      return
    },
    clickGoodsTitle() {
      this.clickGoodsList()
      if (this.isBatchActivePage) return
      if(this.isSafari && this.goodsDetailUrl){
        window.open(forceHttp + this.goodsDetailUrl, '_blank')
      } else {
        this.getGoodsDetailUrl().then(() => {
          window.open(forceHttp + this.goodsDetailUrl, '_blank')
        })
      }
    },
    clickGoodsList(){
      if (this.isBatchActivePage) return
      daEventCenter.triggerNotice({
        daId: '1-7-3-3',
        extraData: {
          item: this.item,
          index: this.index,
          tspId: this.blackFridayLabel?.tagId || '',
          behaviorText: this.getBehaviorText(this.item) || '',
          goodsNameBeforeLabelId: this.goodsNameBeforeLabel.labelId,
        }
      })
    },
    delOrSaveOpt(saveOrDeleted, cb = () => {}) {
      this.saveOrDeleted = saveOrDeleted
      setTimeout(() => {
        this.saveOrDeleted = 3
        setTimeout(() => {
          cb()
        }, 500)
      }, 50)
    },
    initExpose() {
      this.$nextTick(()=>{
        daEventExpose.subscribe && daEventExpose.subscribe({
          keycode: 'cart-item-block`1-7-3-6|1-7-3-4|1-7-5-1|1-7-5-9|1-7-5-21|1-7-5-18|1-7-5-19',
        })
      })
    },
    transformImg,
    onUncheck(data) {
      this.$emit('on-uncheck', data)
    },
    getOtherMark(item){
      let result = []
      if (item?.promotionTag?.typeId == 33) result.push('show_voucher')
      if (this.showEvoluShein) result.push('show_evolushein')
      if (this.maxDiscount != 0) {
        // 0商品图片左上角；1商品行折扣标签；2到手价标签上
        const flag = this.isNewCartDiscountStyle
          ? this.showEstimatedTag ? '2' : '1'
          : '0'
        result.push(`discount_${flag}_${this.maxDiscount}`)
      }
      return result.join('|')
    },
  },
  emits: ['on-uncheck'],
}
</script>

<style lang="less">
/* stylelint-disable selector-max-specificity */
.cart-table-item{
  color: #222222;
  background-color: #FFFFFF;
  position: relative;
  padding: 8px 16px;
  &.not-platform-item + &.not-platform-item{
    &:before{
      content: '';
      width: calc(~"100% - 32px");
      display: block;
      top: 0;
      position: absolute;
      border-bottom: 1px dashed #e5e5e5;
    }
  }
  &_max-pb{
    padding-bottom: 16px;
  }
  .cart-img-gd{
    display: flex;
  }
  .cart-img__gd-footer{
    color: #767676;
    font-size: 12px;
    padding-top: 13px;
    padding-left: 44px;
    .tip {
      display: flex;
      align-items: center;
    }
    i{
      color: #999;
      .margin-r(5px)
    }
    .tip + .tip{
      margin-top: 4px;
    }
    .extra-point-tip{
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: normal;
      position: relative;
      text-overflow: -o-ellipsis-lastline;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  .cart-row-item{
    padding-top: 35px;
  }
  .cart-gd-name {
    position: absolute;
    display: flex;
    align-items: center;
    padding-left: 172px;
    padding: 0 32px 0 172px;
    width: 100%;
    overflow: hidden;
  }
  .gd-name__before-label{
    margin-right: 4px;
    width: 48px;
    height: 12px;
    object-fit: contain;
  }
  a.gd-name{
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #222222 /*rw: #959595*/;
    font-size: 14px;
    display: block;
    text-align: left;
    cursor: pointer;
    z-index: @zindex-hack;
    &:hover{
      font-weight: bold;
      text-decoration: none;
    }
    &:focus{
      text-decoration: none;
    }
  }
  .cart-list__item-total{
    font-weight: 700;
    text-align: right;
    color: #222;
    font-size: 16px;
  }
  &.sold-out {
    .cart-gd-name, .cart-price,.cart-total{
      opacity: .3;
    }
  }
  &.free-item-disable {
    .cart-gd-name, .cart-list__item-img, .cart-list__attr-block, .goods-price-red, .item-store {
      opacity: 0.3;
      pointer-events: none;
    }
    &.club-gift-disable {
      .cart-gd-name, .cart-list__item-img, .cart-list__attr-block, .goods-price-red, .item-store {
        pointer-events: auto;
      }
    }
  }
  .only-left{
    color: #FE3B30;
    font-size: 13px;
    text-align: left;
    margin-top: 8px;
  }
}
.cart-item__remove-tip{
  padding: 0;
  font-size: 14px;
  font-family: Arial-BoldMT, Arial;
  font-weight: normal;
  color: #222222;
  line-height: 16px;
  text-align: center;
  height: 136px;
  transition: height 0.5s ease-in-out;
  i{
    color: @sui_color_safety;
    font-size: 16px;
    vertical-align: bottom;
    .margin-r(4px);
  }
  &.removed{
    height: 0;
  }
}
.cart-item__negative-info {
  padding: 0 16px 0 188px;
  background: #fff;
  & + .cart-table-item {
    padding-top: 22px;
  }
}
</style>

<style lang="less" scoped>
li.cart-row-cell.cart-row-item.cart-info {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 120px;
  padding-top: 24px;

  &.cart-item-content__mask::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#fff, .55);
    z-index: 1;
    pointer-events: none;
  }
  
  .item-store {
    margin-bottom: 8px;
  }
}
.cart-table-item.sold-out :deep(.goods-price-red) {
  opacity: .3;
}
.cart-table-item {
  .cart-row{
    .cart-row-cell{
      display:table-cell;
      vertical-align: top;
      text-align: left;
    }
    .cart-img{
      width: 172px;
      position: relative;
    }
    .cart-item-cell{
      padding-left: 110px;
      position: relative;
      //bottom: 7px;
    }
    .cart-info {
      position: relative;
    }
  }
}
</style>
