import { cartPromotionMapHandle } from '../utils/promotionMapHandle'
import { buildMallFullCourtMap, getAddOnItemStatus } from '../utils'
import { template, htmlDecode } from '@shein/common-function'
import couponAddon from '../components/cartCouponAddon/cartCouponAddonGetter'
import { getAddOnPropData } from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-v2/utils/otherPromo.js'
import { isFreeShipCoupon } from 'public/src/pages/cart_new/utils/freeShipping.js'

import newFreeShippingInfo from './getters/new-free-shipping-info.js'

const handleShippingAnalysisParam = (shippingInfoAvailable = {}, totalPriceAmount = 0) =>{
  const { id, next_shipping_price, charging_type, shipping_detail_obj, max_shipping_price, originPrice, is_free_shipping, diff } = shippingInfoAvailable
  const analysisParam = {}
  const amount = totalPriceAmount

  //免邮运费
  if (originPrice && originPrice.amount) {
    analysisParam.origin_price = originPrice && originPrice.amount
    analysisParam.is_fullshippingactivity = is_free_shipping == 1 ? 1 : 0
  }

  //阶梯运费
  if (shipping_detail_obj) {
    let nowPrice = 0
    for (let i in shipping_detail_obj) {
      let info = shipping_detail_obj[i]
      if (+amount > +info.num && info.num_price) {
        nowPrice = info.num_price.amount
      }
    }
    analysisParam.step_fee = max_shipping_price.amount
    analysisParam.charging_type = charging_type
    analysisParam.num_price = nowPrice
    analysisParam.is_fullshippingactivity = diff <= 0 && !next_shipping_price ? 1 : 0
  }
  analysisParam.shipping_method = id

  return analysisParam
}
const getFreeShippingSatisfied = (shippingInfoAvailable) => {
  const diffPrice = shippingInfoAvailable?.diff_price || {}
  const nowLevel = shippingInfoAvailable?.now_level || 0
  return diffPrice?.amount == 0 ? 1 : (nowLevel > 0 ? 2 : 0)
}

const getFreeShippingProgressRate = (shippingInfoAvailable = {}) => {
  const { diff_price = {}, origin_price = {} } = shippingInfoAvailable
  if (!diff_price.amount || !origin_price.amount) return 0
  if (diff_price.amount <= 0) return 100
  return 100 - (diff_price.amount / origin_price.amount) * 100
}
const getters = {
  ...newFreeShippingInfo,
  mallShippingAnalysisParam (state) {
    let mallShippingAnalysisParam = {
      platform: null // 平台级免邮
    }
    const { cartInfo = {}, mallShippingInfo = {} } = state
    for(let key in mallShippingInfo){
      let amount = 0
      let shipping_free_type = 0
      if(key == 'platform') {
        amount = cartInfo.totalPrice?.amount
        shipping_free_type = 0
      } else {
        amount = cartInfo.mall_list.find(item => item.mall_code == key)?.total_price?.amount
        shipping_free_type = 3
      }
      if(!mallShippingInfo[key].isFirstFreeShipping && mallShippingInfo[key].shippingInfoAvailable) {
        mallShippingAnalysisParam[key] = handleShippingAnalysisParam(mallShippingInfo[key].shippingInfoAvailable, amount)
        mallShippingAnalysisParam[key].shipping_free_type = shipping_free_type
      } else {
        mallShippingAnalysisParam[key] = null
      }
    }
    return mallShippingAnalysisParam
  },
  // listSort(state){
  //   return state.cartList[0] && state.cartList[0].business_model == 1 ? ['localCartList', 'selfCartList'] : ['selfCartList', 'localCartList']
  // },
  /**
   * priority:优先级 1、倒计时活动加需要凑单-》2、需要凑单 -》3、倒计时-》4、普通
   * 包含活动：全场满减、 全场满折、全场返券
  */
  mallFullCourtMap(state){
    return buildMallFullCourtMap(state.integrityPromotionInfo, state.cartInfo || {})
    // let mallMap = {}
    // let singleMallFullPromotionList = Object.values(state.integrityPromotionInfo).filter(promo => ([21, 26].includes(+promo.type_id) || ([14].includes(+promo.type_id) && promo.goods?.type == 3)))
    // singleMallFullPromotionList.forEach(item => {
    //   if(!mallMap[item.mall_code]){
    //     mallMap[item.mall_code] = []
    //   }
    //   mallMap[item.mall_code].push({
    //     promotion_id: item.promotion_id,
    //     typeId: item.type_id,
    //     tip: item.content?.tip || '',
    //     countDownTimeStamp: +item.is_count_down ? item.end_time : '',
    //     add: item.content?.action,
    //     priority: +item.is_count_down && item.content?.action ? 1 : item.content?.action ? 2 : +item.is_count_down ? 3 : 4
    //   })
    // })
    // map.push({
    //   promotion_id: 1111,
    //   typeId: 21,
    //   tip: 'test 21 promotion tips hahahahahahaha add on items',
    //   countDownTimeStamp: Date.now() + 10000000,
    //   add: 1,
    // })
    // map.push({
    //   promotion_id: 2222,
    //   typeId: 26,
    //   tip: 'test 26 promotion tips hahahahahahaha return coupon',
    //   countDownTimeStamp: Date.now() + 9000000,
    //   add: 1,
    // })
    // map.push({
    //   promotion_id: 3333,
    //   typeId: 14,
    //   tip: 'test 14 promotion tips hahahahahahaha full substrict',
    //   countDownTimeStamp: Date.now() + 8000000,
    //   add: 1,
    // })
    // Object.keys(mallMap).forEach(key => mallMap[key].sort((a, b)=> a.priority - b.priority))
    // return mallMap
  },
  addOnPromotionList(state, getters) {
    const promotionIds = [
      20, 21, 14, 26, 25, 9, 1, 15,
      4, 28, 2, 22, 13 // 附属品活动id
    ]
    const { integrityPromotionInfo } = state
    let res = []
    Object.values(integrityPromotionInfo).filter(promo => promotionIds.includes(+promo.type_id)).forEach(item => {
      let courtItem = {
        promotion_id: item.promotion_id,
        typeId: item.type_id,
        tip: item.content?.tip || '',
        countDownTimeStamp: +item.is_count_down ? item.end_time : '',
        isCountDown: !!item.is_count_down,
        diffAmount: item.content?.diffAmount ? item.content.diffAmount.amount || 0 : 0,
        mallCode: item.mall_code,
      }

      // 附属品凑单
      if ([4, 28, 2, 22, 13].includes(+item.type_id)) {
        const { isPicked, isChecked } = getAddOnItemStatus(item, state.cartList, state.soldoutIds)
        courtItem = {
          satisfyFreeShipping: getters.satisfyFreeShipping,
          ...getAddOnPropData(item, state.language, { isPicked, isChecked }),
        }
      }
      if ([1, 9, 14, 15, 25, 26, 20, 21].includes(Number(item.type_id || 0))) courtItem = {
        satisfyFreeShipping: getters.satisfyFreeShipping,
        ...getAddOnPropData(item, state.language)
      }

      res.push(courtItem)
    })
    // 免邮活动凑单弹窗
    let freeShippingInfo = {}
    let isCloseFreeShipping = false // 是否关闭免邮凑单弹窗
    if (state.isMoreMallFreeShippingAddOn) {
      isCloseFreeShipping = !state.mallShippingInfo[state.freeShippingAddOnType]
      freeShippingInfo = state.mallShippingInfo[state.freeShippingAddOnType] || {}
    } else {
      freeShippingInfo = getters.topShippingInfo
    }
    const addOnGoodsInfoList =  freeShippingInfo.shippingInfoAvailable?.add_on_goods_info_list || []
    const isAmountType = !freeShippingInfo.shippingInfoAvailable?.charging_type || freeShippingInfo.shippingInfoAvailable?.charging_type == 3 // 计价方式是否是金额类型，运费活动可能不是金额类型
    const diffPrice =  freeShippingInfo.shippingInfoAvailable?.diff_price || {} // 差额
    res.push({
      promotion_id: getters.firstFreeShipping.firstFreeShippingTip ? 998 : 999,
      addOnType: 'freeShipping',
      title: freeShippingInfo.shippingInfoAvailable?.activity_type == 3 ? state.language.SHEIN_KEY_PC_28505 : state.language.SHEIN_KEY_PC_28506,
      tip: freeShippingInfo.isFirstFreeShipping ? freeShippingInfo.firstFreeShipping.firstFreeShippingTip : freeShippingInfo.shippingTip,
      showCountDown: getters.firstFreeShipping?.showCountDown, // 是否展示倒计时，目前只有首单免邮有倒计时
      countDownTimeStamp: getters.firstFreeShipping?.endTimestamp, // 倒计时结束时间,
      progressRate: getFreeShippingProgressRate(freeShippingInfo.shippingInfoAvailable || {}), // 进度条进度
      isShowProgress: freeShippingInfo.shippingInfoAvailable?.diff_price && freeShippingInfo.shippingInfoAvailable?.origin_price, // 是否展示进度条，目前运费活动返回的字段无法算出进度条进度，所不展示
      activityId: freeShippingInfo.shippingInfoAvailable?.activity_id || '', // 活动id，运费活动没有该字段
      activityType: freeShippingInfo.shippingInfoAvailable?.activity_type || 0, // 活动类型，运费活动是3 免邮活动是1
      diffPrice,
      progressComponentName: 'FreeShippingProgress',
      isSatisfied: getFreeShippingSatisfied(freeShippingInfo.shippingInfoAvailable || {}),
      shippingMethodName: freeShippingInfo.shippingInfoAvailable?.shippingMethodName || freeShippingInfo.shippingInfoAvailable?.shipping_method_name || '', // 运费活动名称，以此判断是否是运费活动切换了
      goodsPrice: isAmountType ? diffPrice?.usdAmount : 0,
      includeTspId: addOnGoodsInfoList.filter(item => item.config_type == 1).map(item =>  item.goods_pool_id).join(','),
      excludeTspId: addOnGoodsInfoList.filter(item => item.config_type == 2).map(item => item.goods_pool_id).join(','),
      mallCode: state.freeShippingAddOnType === 'platform' ? '' : state.freeShippingAddOnType, // mall_code
      isCloseFreeShipping,
      callShippingFreeMallCount: state.callShippingFreeMallCount, // 调用免邮凑单的接口次数
    })
    return res
  },
  // 顶部展示免邮信息：多mall展示平台级、单mall展示mall级
  topShippingInfo (state, getters) {
    if(state.isPlatform) {
      return state.mallShippingInfo[`platform`] || {}
    }
    return getters.singleMallCode ? state.mallShippingInfo[getters.singleMallCode] || {} : {}
  },
  topShippingTips (state, getters){ //顶部展示平台级免邮文案
    return getters.topShippingInfo.shippingTip || ''
  },
  firstFreeShipping (state, getters){
    return getters.topShippingInfo.firstFreeShipping || {}
  },
  cartPromotionMap (state){ //
    return cartPromotionMapHandle({ integrityPromotionInfo: state.integrityPromotionInfo }) // 判断活动是否满足 埋点用
  },
  cartCheckoutPromotionMap(state, getters){
    return cartPromotionMapHandle({ integrityPromotionInfo: state.integrityPromotionInfo, checkedCartPromotionIds: getters.checkedCartPromotionIds }) // 判断活动是否满足 埋点用
  },
  // mall标签 1：兜底、2：平台、3：自营、4：跨境、5：本土，逗号分隔
  // 任意一个MALL的标签mall_tags中，有“平台”标签，则在该站点不隐藏SHEIN店铺组头
  showSheinShop (state) {
    return state.isPlatform && state.siteMallList.find(item => item.mall_tags?.split(',').includes('2')) ? true : false
  },
  // 获取单mall的mallcode
  singleMallCode(state){
    if(!state.isPlatform){
      return state.cartList.length ? state.cartList[0].mall_code : ''
    }
    return ''
  },
  fullCourtPromotionTip (state) {
    const result = {
      /*mall_code:{
        addOnAll: {
          tip: '',
          tips: []
        },
        fullGifts: {
          tip: '',
          tips: []
        },
      }*/

    }
    const integrityPromotionInfo = state.integrityPromotionInfo
    const language = state.language

    if (!integrityPromotionInfo) return result

    Object.values(integrityPromotionInfo).forEach(item => {
      const typeId = item.type_id
      if(!result[item.mall_code]){
        result[item.mall_code] = {}
      }
      if (typeId == '22') {
        if(!result[item.mall_code].addOnAll){
          result[item.mall_code].addOnAll = {
            tip: '',
            tips: [],
            diff_us_amount: [] //美元差价
          }
        }
        const { range, all_diff } = item.promotion_range_info
        const { rules } = item
        result[item.mall_code].addOnAll.tips = []

        rules.forEach((i, index) => {
          const { type, value, value_amount } = all_diff[index]
          const difference = type == 1 ? value : value_amount?.amountWithSymbol
          const checkDifference = type == 1 ? value : Number(value_amount?.usdAmount)

          const rangeListAmount = i.value
          const rangeListAmountWithSymbol = i.value_amount?.amountWithSymbol
          const addOnAllTip = checkDifference ?
            (i.type == 1 ?
              template(rangeListAmount, difference, htmlDecode({ text: language.SHEIN_KEY_PC_20256 }))
              :
              template(rangeListAmountWithSymbol, difference, htmlDecode({ text: language.SHEIN_KEY_PC_19827 })))
            : htmlDecode({ text: language.SHEIN_KEY_PC_16997 })
          if (index == 0) {
            if (range <= 0) {
              result[item.mall_code].addOnAll.tip = addOnAllTip
            } else {
              result[item.mall_code].addOnAll.tip = htmlDecode({ text: language.SHEIN_KEY_PC_16997 })
            }
          }
          result[item.mall_code].addOnAll.tips.push(addOnAllTip)
          result[item.mall_code].addOnAll.diff_us_amount.push(type == 1 ? 0 : Number(value_amount?.usdAmount || 0 ))
        })
      } else if (typeId == '4') {
        if(!result[item.mall_code].fullGifts){
          result[item.mall_code].fullGifts = {
            tip: '',
            tips: [],
            diff_us_amount: [] //美元差价
          }
        }
        const { all_diff } = item.promotion_range_info
        result[item.mall_code].fullGifts.tips = []
        all_diff.forEach((i, index) => {
          const { type, value, value_amount } = all_diff[index]
          const difference = type == 1 ? value : value_amount?.amountWithSymbol
          let fullGiftsTip = ''

          if ((type == 1 && value == 0) || (type != 1 && value_amount?.usdAmount == 0)) {
            fullGiftsTip = htmlDecode({ text: language.SHEIN_KEY_PC_17968 })
          } else {
            fullGiftsTip = template(difference, htmlDecode({ text: language.SHEIN_KEY_PC_19826 }))
          }

          if (index == 0) {
            result[item.mall_code].fullGifts.tip = fullGiftsTip
          }
          result[item.mall_code].fullGifts.tips.push(fullGiftsTip)
          result[item.mall_code].fullGifts.diff_us_amount.push(type == 1 ? 0 : Number(value_amount?.usdAmount || 0 ))
        })
      }
    })
    return result
  },
  flashSaleGroupList (state) {
    let result = {}
    state.cartList.filter(item => item.promotionTypeIds?.includes(10)).forEach(item => {
      let flashSalePromotion = item.product.product_promotion_info?.find(promotion => promotion.type_id == 10)
      if(flashSalePromotion){
        if(!result[flashSalePromotion.promotion_id]){
          result[flashSalePromotion.promotion_id] = []
        }
        result[flashSalePromotion.promotion_id].push(item)
      }
    })
    return result
  },
  promotionGroupList (state) {
    let result = {}
    state.cartList.filter(item => item.limit_mode).forEach(item => {
      let promotion = item.product.product_promotion_info?.find(promotion => promotion.stock_over == '1')
      if (promotion) {
        if (!result[promotion.promotion_id]) {
          result[promotion.promotion_id] = []
        }
        result[promotion.promotion_id].push(item)
      }
    })
    return result
  },
  // 展示obm店铺头
  showOBMStore (state) {
    return state.ABTParams?.Cartshowobmtitle?.param == 'conceal_OBM' ? false : true
  },
  // 展示第三方店铺头
  showThreeStore (state) {
    return state.ABTParams?.Showplatformtitle?.param == 'conceal' ? false : true
  },
  showQuickShipTag(state) {
    return !state.isPlatform && state.ABTParams['TradeQuickship'] && state.ABTParams['TradeQuickship'].param == 'show_QuickShipping' && !!state.cartQsTagInfo.last_quickship_tag ? true : false
  },
  /**
   * wiki.pageId=1004875731
   * is_has_quota 付费会员额度是否受限 1-有限制 0-不限制
   */
  isHasQuota(state, getters){
    return getters.promotionGrantAmountInfo.is_has_quota == 1
  },
  /**
   * wiki.pageId=1021332568
   * vip_sum_over 付费会员额度超限标识  1-超限 0-不超限
   * @return {boolean}
   */
  vipSumOver(state, getters){
    return getters.promotionGrantAmountInfo.vip_sum_over == 1
  },
  promotionGrantAmountInfo (state) {
    return state.cartInfo && state.cartInfo.promotion_grant_amount_info || {
      // "cycle_grant_amount": {
      //   "amount": "200",
      //   "amountWithSymbol": "$300",
      // },
      // "cycle_used_grant_amount": {
      //   "amount": "23",
      //   "amountWithSymbol": "$330",
      // },
      // "vip_sum_over": 1,
      // is_has_quota:1
    }
  },
  primeVipOverQuotaTip(state, getters){
    const { cycle_used_grant_amount, cycle_grant_amount } = getters.promotionGrantAmountInfo
    return getters.isHasQuota && cycle_used_grant_amount?.amountWithSymbol && cycle_grant_amount?.amountWithSymbol ? template(cycle_used_grant_amount.amountWithSymbol, cycle_grant_amount.amountWithSymbol, state.language.SHEIN_KEY_PC_23940) : ''
  },
  showDiscountlistshowlimitexceededtipAbt(state){
    return state.ABTParams['Discountlistshowlimitexceededtip']?.param === 'off' ? false : true
  },
  showPrimeVipOverQuota(state, getters){
    return getters.vipSumOver && getters.primeVipOverQuotaTip && getters.showDiscountlistshowlimitexceededtipAbt
  },
  // 闪购超限展示形式
  flashSaleOverShowType () {
    return 'toast'
  },
  underPriceGroupList (state) {
    let result = {}
    state.cartList.filter(item => item.promotionTypeIds?.includes(30)).forEach(item => {
      let flashSalePromotion = item.product.product_promotion_info?.find(promotion => promotion.type_id == 30)
      if(flashSalePromotion){
        if(!result[flashSalePromotion.promotion_id]){
          result[flashSalePromotion.promotion_id] = []
        }
        result[flashSalePromotion.promotion_id].push(item)
      }
    })
    return result
  },
  underPriceAbtValue (state) {
    return state.ABTParams.UnderPrice?.param?.UnderPriceShow == 'A' ?  true : false
  },
  checkedCartPromotionIds (state) {
    let cartPromotionIds = []
    state.cartList.filter(item => item.is_checked).forEach(cartItem => {
      cartPromotionIds.push(...cartItem.promotionIds)
    })
    let cartPromotionIdsSet = new Set(cartPromotionIds)
    cartPromotionIds = [...cartPromotionIdsSet]
    return cartPromotionIds
  },
  ...couponAddon, // 混入优惠券凑单聚合数据, 均以coupon开头
  satisfyFreeShipping(state, getters) {
    const { isPlatForm, mallList, mallShippingInfo } = state
    const { topShippingInfo, firstFreeShipping } = getters
  
    if (
      topShippingInfo.showShipSupplement == false &&
      (
        (topShippingInfo.isFirstFreeShipping && firstFreeShipping.firstFreeShippingTip) ||
        (!topShippingInfo.isFirstFreeShipping && topShippingInfo.shippingTip )
      )
    ) {
      return true
    }
  
    if (isPlatForm) {
      // mall级
      let flag = 0
      mallList.forEach(mall => {
        let mall_shipping = mallShippingInfo[mall.mall_code]
        if(mall_shipping && mall_shipping?.showShipSupplement == false){
          flag++
        }
      })
      return flag == mallList.length
    }
  
    return false
  },
  // @desc
  // wiki.pageId=1311685888
  // 单mall：当前有满足门槛的免邮指定券 or 当前站点下有一个或一个以上的运输方式已免邮
  // 多mall：当前站点下有一个或一个以上的运输方式已免邮
  checkoutFreeShippingState(state) {
    const isFreeShipping = shipping => shipping?.some(item => item?.is_free_shipping === 1)
    const mallAllFree = state?.mallShippingInfo?.mall_transport_free_list?.every(item => isFreeShipping(item.transport_free_list))
    
    // 多mall
    if (state.isPlatForm) {
      return mallAllFree
    }

    // 当前有满足门槛的免邮指定券
    const hasFreeCouponItem = state?.couponInfo?.usableCouponList?.some(item => isFreeShipCoupon(item))
    return hasFreeCouponItem || mallAllFree
  },
  // 购物车折扣角标样式是否为a方案，默认为onA，即不配置为off时都为onA，若新增其他样式方案，要按需修改此逻辑
  cartDiscountLogoA (state) {
    return state.ABTParams?.Discountlogo?.param?.add_discount_logo_switch != 'off'
  },
  // 新增CartrowEstimatedCountdown, 是否展示购物车到手价标签倒计时
  isShowCartrowEstimatedCountdown(state) {
    return state.cartAbtInfo?.CartrowEstimatedCountdown?.param?.cartrow_estimated_countdown == 'on'
  },
  // 获取优惠券信息，此次需求新增节点coupon_infos
  cartCouponInfos(state) {
    return state.cartInfo?.coupon_infos || []
  },
  // 购物车筛选是否常驻置顶
  isFilterControlTop(state) {
    return state.cartAbtInfo?.cartSelect?.param?.control_top == 'on'
  },
  // 购物车筛选ui方案，off-保持线上，A-橙底橙字、icon变更，B-黑底白字
  filterControlStyle(state) {
    return state.cartAbtInfo?.cartSelect?.param?.control_style || 'off'
  },
  /**
   * 购物车支持券筛选-开启券筛选或券凑单
   * select-券筛选，couponhelper-券凑单（默认）
   * @param {*} state 
   * @returns string
   */
  couponSelectType(state) {
    return state.cartAbtInfo?.cartSelect?.param?.CouponSelectType || 'couponhelper'
  },
  /**
   * 购物车支持券筛选-开启券筛选后，展示券前价还是券后价
   * beforecouponprice-券前价，aftercouponprice-券后价（默认）
   * @param {*} state 
   * @returns string
   */
  selectPrice(state) {
    return state.cartAbtInfo?.cartSelect?.param?.SelectPrice || 'aftercouponprice'
  },
  /**
   * 购物车筛选标签展示顺序
   * flash_sale-闪购，oos-即将售罄，pricedown-降价，coupon-优惠券，limit_off，discount_limit，under_price，5star，30day_sale，30day_additem
   * 兜底默认'flash_sale,pricedown,oos'
   * @param {*} state 
   * @returns ''
   */
  filterLabelOrder(state) {
    return state.cartAbtInfo?.cartSelect?.param?.CouponShowOrder || 'flash_sale,pricedown,oos'
  },
  /**
   * 筛选多标签收纳样式
   * off-不收纳，保持横向滚动，text-收纳，入口为more文字样式，logo-收纳，入口为logo样式
   */
  moreFilterControl(state) {
    return state.cartAbtInfo?.cartSelect?.param?.ControlSelectlogo || 'off'
  },
  // 是否开启购物车券筛选与底部feed流联动
  isCartSelectRecommend(state) {
    return state.cartAbtInfo?.cartSelect?.param?.cartSelect_recommend == 'on'
  },
  //是否合规价格站点
  isSuggested(state) {
    return state.locals?.IS_SUGGESTED || false
  },
  incentiveTimeConfig() {
    // TODO: read on apollo
    return {
      leftCountDownTime: 1000 * 60 * 5,
      noInteractionTimeout: 3000,
      popoverKeepTime: 3000,
      incentiveGapTime: 1000 * 60
    }
  },
  // 展示checkout按钮利诱文案
  incentiveBtnType(state) {
    const abtArr = state.ABTParams?.IncentiveInform?.param?.IncentiveButton?.split(',') || []
    const checkedCarts = state?.cartList.filter(item => item.is_checked == 1)
    for (let i = 0; i < abtArr?.length; i++) {
      if (abtArr[i] === 'coupon') {
        if (state.couponFilterLabelData?.usableCouponList?.length > 0 || state.couponInfo?.usableCouponList?.length > 0) {
          return abtArr[i]
        } else {
          continue
        }
      }
      if (abtArr[i] === 'flash_sale') {
        if (checkedCarts?.some(v => v.product?.product_promotion_info?.some(p => p.type_id == 11 || (p.type_id == 10 && v.flashType == '1')))) {
          return abtArr[i]
        } else {
          continue
        }
      }
      if (abtArr[i] === 'mark_down') {
        if (checkedCarts?.some(v => v.reduce_price?.reduceAmount?.amount > 0)) {
          return abtArr[i]
        } else {
          continue
        }
      }
      if (abtArr[i] === 'oos') {
        if (checkedCarts?.some(v => v.isAlmostOosItem)) {
          return abtArr[i]
        } else {
          continue
        }
      }
    }

    return ''
  },
  // 是否开启evoluSHEIN标签
  switchOnEvoluSHEIN(state) {
    return state.cartAbtInfo?.evoluSHEINShow?.param?.evoluSHEIN_show === 'on'
  },
  // 商品折扣是否以商品行折扣标签展示（TR-37526）
  isNewCartDiscountStyle(state, getters) {
    if (getters.isSuggested) return false
    const cart_discount_style = state.cartAbtInfo?.cartDiscountStyle?.param?.cart_discount_style || 'new'
    return cart_discount_style === 'new'
  },
  isNewReturnFlag(state) {
    return state.cartAbtInfo?.Cartrefund?.param?.cart_nonrefundable === 'on'
  },
  // 是否展示折扣倒计时及明细
  showCartPromotionsDetail(state) {
    return state.cartAbtInfo?.cartPromotions?.param?.cart_promotions_detail != 'off'
  },
  // 是否在价格明细弹窗中展示倒计时
  showDetailPriceCountDown() {
    return true
  },
  // 凑单组件点击商品是否跳转商详
  cartAddTradeGoodsDetailSwitch(state) {
    return state.cartAbtInfo?.CartaddGoodsdetailSwitch?.param?.cartadd_trade_goodsdetail_switch === 'on'
  },
  // 自研服务的定制商品图片域名列表
  customServiceImgDomains(state) {
    return state.locals?.CUSTOMIZATION_PRODUCT_IMAGE_DOMAINS?.CUSTOM_SERVICE || ['ltwebstatic.com', 'shein.com', 'romwe.com']
  },
  // 第三方服务的定制商品图片域名列表
  thirdPartyServiceImgDomains(state) {
    return state.locals?.CUSTOMIZATION_PRODUCT_IMAGE_DOMAINS?.THIRD_PARTY_SERVICE || ['staticmeow.com/', 'customeow.io/', 'maiyuan.online/']
  },
  // 可切mall商品仍然放在失效商品组展示
  cartMallSwitchGoods(state) {
    return state.cartAbtInfo?.CartOOSGoodsPosition?.param?.cart_mall_switch_goods === 'on'
  },
  // 可切换其他颜色或尺码商品仍然放在失效商品组展示
  cartSaleAttrSwitchGoods(state) {
    return state.cartAbtInfo?.CartOOSGoodsPosition?.param?.cart_saleattr_switch_goods === 'on'
  },
  cartStoreFreegift(state) {
    return state.cartAbtInfo?.cartStoreFreeGift?.param?.cart_store_freegift == 'on'
  },
  // 整车明细是否外露
  isExposePriceDetail(state) {
    const ControlPriceDetail = state.cartAbtInfo?.PriceDetail?.param?.ControlPriceDetail  || 'show'
    return ControlPriceDetail === 'show'
  },
  // 购物车券筛选接csc平台
  couponFilterIsCsc(state) {
    return state.cartAbtInfo?.cartSelect?.param?.coupon_recommend == 'new_logic' && !state.cscCouponIsError
  },
  // 购物车筛选信息承接
  showFilteredInformation(state) {
    return state.cartAbtInfo?.cartSelect?.param?.selectinform == 'on'
  },
  // SFS商品标签及利益点 pageId=1492949172
  CartOrderSFSVisual(state) {
    return {
      visual_tabs: state.cartAbtInfo?.CartOrderSFSVisual?.param?.visual_tabs == 'show_tabs',
      visual_benefit_point: state.cartAbtInfo?.CartOrderSFSVisual?.param?.visual_benefit_point == 'show_benefit_point'
    }
  },
  isSheinClubMember(state) {
    return !!state.cartInfo?.promotion_member_info?.length
  },
  controlActivityOver(state) {
    return state.cartAbtInfo?.ActivityOver?.param?.ControlActivityOver == 'new'
  },
  // BFF 接口返回的购物车列表数据
  cartListMap(state) {
    return Object.fromEntries(
      (state?.mallCartInfo?.mallCarts ?? [])
        .flatMap(mallCart => mallCart.shops ?? [])
        .flatMap(shopItem => shopItem.contentData.flatMap(contentItem => contentItem?.productLineInfoList ?? []))
        .map(shopItem => [shopItem.id, shopItem])
    )
  },
  // BFF 接口返回的购物车活动库存超限拦截信息
  promotionInterceptionV2(state) {
    return state.interceptResultInfo?.promotionInterceptionV2 ?? {}
  }
}
export default getters
