<template>
  <div
    class="reduce-price-icon"
    :style="{width: width+'px', height: height+'px'}"
  >
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 3H8V7H4V3Z"
        :fill="fillColor"
      />
      <path
        d="M4 2H8V1H4V2Z"
        :fill="fillColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 11L2 6.875H10L6 11Z"
        :fill="fillColor"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'CartReducePriceIcon',
  props: {
    width: {
      type: [Number, String],
      default: '12'
    },
    height: {
      type: [Number, String],
      default: '12'
    },
    isRw: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    fillColor(){
      return this.isRw ? '#B40954' : '#C44A01'
    }
  }
}
</script>

<style lang="less" scoped>
.reduce-price-icon{
  display: inline-flex;
  position: relative;
  margin-right: 2px;
  vertical-align: bottom;
}
</style>
