const state = {
  // stopAllSwiper: false,
  originCartNum: 0,
  showSkeleton: 0,
  cartlists: {},
  mallInfo: [],
  cartAbtInfo: {},
  cartIntegrityPromotionInfo: {},
  cartShippingFreeInfo: {},

  logoList: [],
  locals: {},
  isSSR: false,
  loginStatus: false,
  isPlatform: false, // 是否是平台化多mall
  language: {},
  cartInfo: {}, // 购物车信息
  cartList: [], // 购物车列表
  cartSumQuantity: 0, //购物车数量
  ABTParams: { // abt实验
    ReselectSimilar: '',
    LowpriceUser: '',
    CouponAddToCartToast: {},
    addnewhot: {},
    TaxSwitch: {},
    CartNotCheckedEstimate: {},
    CartrowEstimatedCountdown: {},
    DiscountInfoSwitch: {},
    cartSelect: {},
    CartAddress: {},
    CartUnpaidOrder: {},
    IncentiveInform: {},
    evoluSHEINShow: {},
    storeiconchange: {},
    QSinformation: {},
    cartDiscountStyle: {},
    Cartrefund: {},
    cartPromotions: {},
    CartaddGoodsdetailSwitch: {},
    PriceDetail: {},
    cartStoreFreeGift: {},
    CartOrderSFSVisual: {},
  },
  integrityPromotionInfo: {}, // 促销活动信息 新旧车都在用，数据信息结构不一样
  storeSelectsInfo: {}, // 店铺选品信息，拼接店铺链接用
  // selfCartList: [{store_code:'shein', store_list: []}], // 自营商品
  // localCartList: [], // 第三方店铺商品
  soldoutIds: [],
  soldoutItems: [],
  cartsId: [],
  batchActive: false, // 批量
  bigLoading: 0,
  shippingInfo: {}, //免邮信息
  showSoldOutDialog: false,
  lastCheckStockTime: 0,
  multipleGears: 0,
  mallCartList: [],
  mallList: [], // 购物车接口返回的mall列表
  mallShippingInfo: {},
  drawerGetCoupon: false, // 打开获取优惠券抽屉
  getCouponMallInfo: {}, // 获取优惠券需要的mall信息
  siteMallList: {}, // 站点的mall列表
  addForCouponConfig: {},
  outStockCarts: [],
  blackFridayImgGoods: {},
  noticeMessage: '',
  flashSaleOverLimit: {}, //闪购超限支持原价购买提示类型{cartId:{showType:toast,overType:singleOver/memberOver,alreadyShow:true/false,showStatus:true/false,timefn}}
  promotionOverLimit: new Set(), //活动库存超限
  isReflashGetCouponFetch: false, // 是否重新请求领券入口接口
  couponInfo: {}, // 购物车优惠券信息
  /* --- 购物车筛栏start --- */
  goodsFilterLabels: [],     // 商品筛选标签（活动筛选、券筛选）
  filterType: 'none',        // 当前选中的筛选标签类型, 默认值为none, 不可为空字符串。券筛选时值为券id，可通过!isNaN(filterType)与活动筛选区分
  couponFilterLabelData: {}, // 券筛选标签的来源数据(券助手接口)，但进入购物车或手动刷新才更新数据，所以需要与couponInfo解耦单独维护
  cscCouponIsError: false,   // csc平台券接口是否报错，报错时走券助手接口
  couponLabelFromCsc: {},    // 券筛选标签的来源数据(csc接口)，通过abt参数coupon_recommend来控制用couponFilterLabelData还是couponLabelFromScs
  targetCouponInfo: {},      // 当前选中的作为筛选条件的券
  promotionLabels: [],       // 优惠活动类+商品信息类筛选标签
  dynamicLabel: null,        // 筛选栏机动位标签
  filterHighWeightGoods: {}, // 筛选后高优商品信息
  /* --- 购物车筛选栏end  */
  taxInclusiveTip: '', // 包税信息
  appendageDrawer: {
    promotionId: '', // 附属品选择弹窗的促销id
    fromType: '', // addItem:凑单页，pick:直接唤起附属品选择弹窗(默认)
    fullGiftShow: false, // 新满赠
    addBuyShow: false, // 新加价购
    buyGiftShow: false, // 新买赠
    clubGiftShow: false, // 付费会员免费礼物权益
  },
  asyncComponentLoadedStatus: {
    cartFullGiftsDrawIsLoaded: false,
    cartAddBuyDrawIsLoaded: false,
    cartBuyGiftDrawIsLoaded: false,
    batchActiveIsLoaded: false,
    cartClubGiftDrawIsLoaded: false,
  },
  freeShippingAddOnType: '', // 免邮凑单类型平台级或mall级 platform或者mall_code
  isMoreMallFreeShippingAddOn: false, // 当前免邮凑单的是否是多mall的
  realTimeBehaviorLabels: [],
  cartItemMap: {},
  callShippingFreeMallCount: 0, // 调用免邮凑单的接口次数
  bottomActivityConfig: { // 底部活动更新需要等待3种数据都准备好
    couponIsReady: false,
    freeshipIsReady: false,
  },
  unPaidOrderInfo: {}, // 未支付订单信息
  inducementInfoList: [], // 原始利诱点信息
  inducementSortTypes: [], // 利诱点类型排序，每显示一个从最前面移到最后
  inducementSortList: [], // 利诱点信息排序，每次进入购物车根据inducementSortTypes排一次序
  showIncentiveProductListDialog: false, // 展示利诱点商品列表弹窗
  showIncentivePopover: false, // 展示利诱点popover
  incentivePopoverSwitchTimer: null, // 利诱点popover switch定时器
  incentivePopoverKeepTimer: null, // 利诱点popover keep定时器
  showIncentiveTimes: 0, // 展示利诱点次数 max is 3
  incentivePopoverContent: {}, // 利诱点popover内容
  incentiveItemIds: [], // 利诱点商品id
  // 购物车使用bff接口开关
  useBffApi: {
  },
  // 购物车quickship标签信息
  cartQsTagInfo: {
    isShowNewStyle: false,  // 是否为新样式（商品行）
    text: '',               // quickship标题文案（标签前半部分）
    qsTimelinessInfo: {     // quickship时效信息
      isLocationTop: false, // 是否展示在购物车顶部
      tip: '',              // quickship时效文案（标签后半部分）
    },
    last_quickship_tag: '', // 发货时效最晚的 QS 信息
    isJp: false, // 是否为日本收货地址
  },
  // BFF 接口返回的购物车超限拦截信息
  interceptResultInfo: {},
  showPromotionInterceptionDialog: false,
}

export default state
