<template>
  <div class="empty-bag__delete-after">
    <empty-cart-vue
      :to-shop="true"
      :is-real-show="isRealShow"
    />
  </div>
</template>

<script>
import EmptyCartVue from './../emptyCart/index.vue'

export default {
  name: 'CartListEmpty',
  components: {
    EmptyCartVue
  },
  props: {
    isRealShow: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" scoped>
.empty-bag__delete-after{
  text-align: center;
  background: #FFFFFF;
  min-height: 340px;
}
</style>
