<script setup>
import { computed, ref, watch, nextTick, onMounted } from 'vue'
import { useStore } from 'vuex'
import { Icon } from '@shein-aidc/icon-vue3'
import { template } from '@shein/common-function'
import { getCouponDiff, getDiscountByLevel } from '@/public/src/pages/cart_new/utils/cartFilterLabels.js'
import { is0ThresholdCoupon } from 'public/src/pages/cart_new/utils/coupon.js'

const store = useStore()
const lang = computed(() => store?.state?.language)
const locals = computed(() => store?.state?.locals)

const targetCouponInfo = computed(() => store?.state?.targetCouponInfo)
// 判断当前券是否已凑满最高门槛
const isSatisfied = computed(() => {
  const { satisfied_range, other_coupon_rule } = targetCouponInfo?.value
  return Number(satisfied_range) >= other_coupon_rule?.length
})

watch(targetCouponInfo, (newVal, oldVal) => {
  if (newVal?.id && newVal?.id !== oldVal?.id) {
    daEventCenter.triggerNotice({
      daId: '1-7-3-53',
      extraData: {
        coupon_type: newVal.couponType,
        is_add: isSatisfied.value ? 0 : 1
      }
    })
  }
}, { immediate: true })

// 达到最高门槛，触发一次气泡自动弹出
watch(isSatisfied, (newVal, oldVal) => {
  if (!oldVal && newVal) {
    if (window?.sessionStorage.getItem('CartCouponSatisfiedPop')) return
    autoShowPopover()
    window?.sessionStorage.setItem('CartCouponSatisfiedPop', true)
  }
})

/**
 * 优惠券使用情况提示
 * 0门槛券          SHEIN_KEY_PC_30905
 * 未达到最低门槛    SHEIN_KEY_PC_30136
 * 达到最高门槛      SHEIN_KEY_PC_30138
 * 达到最低未达到最高 SHEIN_KEY_PC_30137
 */
const couponTip = computed(() => {
  if (!targetCouponInfo.value?.id) return ''
  const { SHEIN_KEY_PC_30136, SHEIN_KEY_PC_30137, SHEIN_KEY_PC_30138, SHEIN_KEY_PC_30905 } = lang.value
  const { satisfied_range, other_coupon_rule, realDiscountPrice } = targetCouponInfo.value
  if (is0ThresholdCoupon(targetCouponInfo.value)) {
    return template(
      getDiscountByLevel(targetCouponInfo.value, 0, store.state.locals),
      SHEIN_KEY_PC_30905,
    )
  } else if (satisfied_range == '0') {
    return template(
      getCouponDiff(targetCouponInfo.value, true),
      getDiscountByLevel(targetCouponInfo.value, 0, store.state.locals),
      SHEIN_KEY_PC_30136,
    )
  } else if (Number(satisfied_range) >= other_coupon_rule?.length) {
    return template(
      realDiscountPrice?.amountWithSymbol,
      SHEIN_KEY_PC_30138,
    )
  } else {
    return template(
      realDiscountPrice?.amountWithSymbol,
      getCouponDiff(targetCouponInfo.value, true),
      getDiscountByLevel(targetCouponInfo.value, satisfied_range, store.state.locals),
      SHEIN_KEY_PC_30137,
    )
  }
})

// 气泡相关
const showPop = ref(false)
const isOverflow = ref(false)
const selectPrice = computed(() => store?.state?.selectPrice)
/**
 * 气泡内容
 * 未达到门槛 - 券前价   SHEIN_KEY_PC_30135
 * 未达到门槛 - 券后价   SHEIN_KEY_PC_30139
 * 达到门槛 - 自动用券   SHEIN_KEY_PC_30140
 * 达到门槛 - 非自动用券 SHEIN_KEY_PC_30141
 */
const popoverTip = computed(() => {
  const autoUseCoupon = Number(targetCouponInfo?.value.is_best) == 1
  const isBeforePrice = selectPrice.value == 'beforecouponprice' 
  const {
    SHEIN_KEY_PC_30140,
    SHEIN_KEY_PC_30141,
    SHEIN_KEY_PC_30135,
    SHEIN_KEY_PC_30139,
  } = lang.value

  if (isSatisfied.value) {
    return autoUseCoupon ? SHEIN_KEY_PC_30140 : SHEIN_KEY_PC_30141
  } else {
    return isBeforePrice ? SHEIN_KEY_PC_30135 : SHEIN_KEY_PC_30139
  }
})
// 监听couponTip是否溢出
watch(couponTip, async (newVal) => {
  if (newVal) {
    await nextTick()
    const popoverRect = document.querySelector('.j-pop-icon')?.getBoundingClientRect()
    const couponTipRect = document.querySelector('.j-coupon-tip')?.getBoundingClientRect()
    isOverflow.value = popoverRect?.bottom > couponTipRect?.bottom
  }
}, { immediate: true })
watch(showPop, (newVal) => {
  if (newVal) {
    daEventCenter.triggerNotice({ 
      daId: '1-7-3-55' 
    })
  }
}, { immediate: true })
/**
 * 气泡自动弹出逻辑
 * 一次会话最多弹两次，一次是选中标签时的， 一次是达到最高门槛时
 */
const autoShowPopover = () => {
  let countdown = 3
  showPop.value = true
  let intervalId = setInterval(() => {
    countdown -= 1
    if (countdown <= 0) {
      clearInterval(intervalId)
      showPop.value = false
    }
  }, 1000)
}

// 倒计时超过72小时不展示
const showCountDown = computed(() => {
  const endTimeStamp = Number(targetCouponInfo.value?.end_date)
  const currentTimeStamp = Math.floor(Date.now() / 1000)
  const differenceInHours = (endTimeStamp - currentTimeStamp) / 3600
  return differenceInHours < 72
})

// 调用凑单组相关
const handleAddOn = () => {
  const { couponType, satisfied_range, coupon } = targetCouponInfo?.value
  store.commit('updateAddForCoupon', {
    show: true,
    type: 'coupon',
    state: 'cart_filter_coupon_add',
    coupon: coupon,
    config: {
      isClickToDetail: false,
    }
  })
  daEventCenter.triggerNotice({
    daId: '1-7-3-54',
    extraData: {
      coupon_type: couponType,
      level: Number(satisfied_range)
    }
  })
}

onMounted(async () => {
  await nextTick()
  if (window?.sessionStorage.getItem('CartCouponFirstPop')) return
  autoShowPopover()
  window?.sessionStorage.setItem('CartCouponFirstPop', true)
})

</script>

<template>
  <div class="cart-filter-coupon">
    <Icon
      class="icon"
      name="sui_icon_coupon_discount_24px" 
      size="18px" 
    />
    <div class="coupon-tip-left">
      <div
        class="j-coupon-tip"
        :class="isOverflow ? 'coupon-tip-overflow' : 'overflow-line2'"
      >
        <div
          class="text"
          :class="{ 'overflow-line2': isOverflow }"
          v-html="couponTip"
        ></div>
        <s-popover
          v-model="showPop"
          trigger="hover"
          class="j-pop-icon pop-icon"
          theme="dark"
          placemen="bottom"
          :content="popoverTip"
        >
          <template #reference>
            <Icon
              name="sui_icon_doubt_12px_2"
              size="12px"
              color="rgba(0, 0, 0, 0.6)"
            />
          </template>
        </s-popover>
      </div>
      <div
        v-if="showCountDown"
        class="count-down"
      >
        <span class="end-in">{{ lang.SHEIN_KEY_PC_26828 }}</span>
        <count-down-time
          class="time"
          font-size="12px"
          not-hide
          not-reload
          :time-stamp="Number(targetCouponInfo.end_date)"
          :show-type="'inline-promotion'"
        />
      </div>
    </div>
    <div
      v-if="!isSatisfied"
      class="add"
      @click="handleAddOn"
    >
      {{ lang.SHEIN_KEY_PC_15215 }}
      <Icon 
        name="sui_icon_more_right_12px"
        :is-rotate="locals.GB_cssRight"
        size="12px"
      />
    </div>
  </div>
</template>

<style lang="less" scoped>
.cart-filter-coupon {
  width: 100%;
  display: inline-flex;
  align-items: flex-start;
  padding: 12px 16px;
  border: 0.5px solid rgba(250, 99, 56, 0.30);
  background: #FFF6F3;
  margin-bottom: 10px;
  .coupon-tip-left {
    flex: 1;
    margin: 0 8px;
    .text {
      display: inline;
      font-size: 13px;
      line-height: 15px;
    }
    .count-down {
      margin-top: 4px;
      display: flex;
      align-items: center;
    }
    .end-in {
      margin-right: 4px;
      font-size: 12px;
    }
    .cart__down-time {
      margin-top: 0
    }
  }
  .overflow-line2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    .text {
      margin-right: 4px;
    }
  }
  .coupon-tip-overflow {
    display: inline-flex;
    align-items: flex-end;
    .text {
      flex: 1;
      margin-right: 4px;
    }
    .pop-icon {
      width: 12px;
    }
  }
  .add {
    cursor: pointer;
    min-width: 44px;
    font-size: 13px;
    font-weight: 700;
    color: #222;
    font-family: Arial;
  }
}
.text {
  :deep(strong) {
    font-weight: normal;
    font {
      font-weight: bold;
    }
  }
}
</style>
