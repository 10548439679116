<template>
  <s-dialog
    v-if="modalOptions"
    v-model:visible="modalOptions.show"
    v-ada-modal="{
      show: modalOptions.show,
      first: () => {
        $refs.commonModalYes.focus();
      }
    }"
    class="cart-commonModal"
  >
    <template #title>
      {{ modalOptions.text }}
    </template>
    <template #footer>
      <s-button-group hor>
        <s-button-group-item @click="modalOptions.cancelCallback">
          {{ modalOptions.canceltext || language.SHEIN_KEY_PC_15738 }}
        </s-button-group-item>
        <s-button-group-item
          ref="commonModalYes"
          :type="['primary']"
          @click="modalOptions.okCallback"
        >
          {{ modalOptions.oktext || language.SHEIN_KEY_PC_15737 }}
        </s-button-group-item>
      </s-button-group>
    </template>
  </s-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CommonModalTip',
  props: {
    modalOptions: {
      type: Object,
      default(){
        return null
      }
    }
  },
  computed: {
    ...mapState(['language'])
  }
}
</script>
