/**
 * 删除商品行提示类型
 */
const DELETE_TIPS_TYPES = Object.freeze({
  /**
   * 展示未来可能的折扣信息、取消勾选按钮、删除该商品行按钮
   * 
   * 判断条件：单商品行、abt为ControlRetentionShow=on,promotecut、商品行为勾选状态、折扣金额为0
   */
  PROMTOTECUT_CHECKED_NO_DISCOUNT: 1,
  /**
   * 展示折扣信息、挽留文案、取消勾选按钮、删除该商品行按钮
   * 
   * 判断条件：单商品行、abt为ControlRetentionShow=on,promotecut、商品行为勾选状态、折扣金额不为0
   */
  PROMTOTECUT_CHECKED_HAS_DISCOUNT: 2,
  /**
   * 展示未来可能的折扣信息、移入收藏夹按钮、删除该商品行按钮
   * 
   * 判断条件：单商品行、abt为ControlRetentionShow=on,promotecut、商品行为未勾选状态
   */
  PROMTOTECUT_UNCHECKED: 3,
  /**
   * 展示找相似文案、删除并找相似按钮、删除该商品行按钮
   * 
   * 判断条件：单商品行、abt为ControlRetentionShow=on,similar
   */
  SIMILAR: 4,
  /**
   * 展示未来可能的折扣信息、移入收藏夹按钮、删除批量商品行按钮
   * 
   * 判断条件：批量操作、abt为ControlBatchDelete=on,normal
   */
  BATCH_NORMAL: 5,
  /**
   * 展示未来可能的折扣信息、移入收藏夹按钮、删除批量商品行按钮（删除后走找相似逻辑）
   * 
   * 判断条件：批量操作、abt为ControlBatchDelete=on,similar
   */
  BATCH_SIMILAR: 6,
})

/**
 * 提示弹窗的标题
 */
const TITLE_LANGUAGE_KEYS = Object.freeze([
  '',
  'SHEIN_KEY_PC_27970',
  'SHEIN_KEY_PC_27970',
  'SHEIN_KEY_PC_27973',
  '',
  'SHEIN_KEY_PC_27973',
  'SHEIN_KEY_PC_27973'
])

/**
 * 提示弹窗的挽留文案提示（找相似、存在优惠）
 */
const TEXT_LANGUAGE_KEYS = Object.freeze([
  '',
  '',
  'SHEIN_KEY_PC_27971',
  '',
  'SHEIN_KEY_PC_27967',
  '',
  '',
])

/**
 * 未来可能的折扣信息列表
 */
const DISCOUNT_INFO_LIST = [
  {
    iconName: 'sui_icon_activity_18px',
    languageKey: 'SHEIN_KEY_PC_27975',
    name: 'Discounts'
  },
  {
    iconName: 'sui_icon_decrease_fill_24px',
    languageKey: 'SHEIN_KEY_PC_27976',
    name: 'Price Drops'
  },
  {
    iconName: 'sui_icon_coupon_40px',
    languageKey: 'SHEIN_KEY_PC_27977',
    name: 'Coupon'
  }
]

/**
 * 确认按钮的文案
 */
const CONFIRM_BTN_LANGUAGE_KEYS = Object.freeze([
  '',
  'SHEIN_KEY_PC_27972',
  'SHEIN_KEY_PC_27972',
  'SHEIN_KEY_PC_27974',
  'SHEIN_KEY_PC_27968',
  'SHEIN_KEY_PC_27974',
  'SHEIN_KEY_PC_27974',
])

/**
 * 删除按钮的文案
 */
const DELETE_BTN_LANGUAGE_KEYS = Object.freeze([
  '',
  'SHEIN_KEY_PC_27969',
  'SHEIN_KEY_PC_27969',
  'SHEIN_KEY_PC_27969',
  'SHEIN_KEY_PC_27969',
  'SHEIN_KEY_PC_27969',
  'SHEIN_KEY_PC_27969',
])

export {
  DELETE_TIPS_TYPES,
  TITLE_LANGUAGE_KEYS,
  DISCOUNT_INFO_LIST,
  TEXT_LANGUAGE_KEYS,
  CONFIRM_BTN_LANGUAGE_KEYS,
  DELETE_BTN_LANGUAGE_KEYS,
}
