<template>
  <div
    class="cart_item-header"
    :type-id="promotionContent.typeId"
    :class="{'not-promoGoods':(!item || showThreeStore) && isSingleMall}"
  >
    <section
      class="cart-item__header-section"
      :class="{'platform-header-section': !isSingleMall}"
    >
      <div class="section-content">
        <sui_icon_activity_16px
          v-if="!isSingleMall"
          class="pro-tag"
          size="16px"
        />
        <div>
          <p
            v-if="isSingleMall"
            class="cart_item-title full-title"
            tabindex="0"
            :aria-label="language.SHEIN_KEY_PC_15966 + ',' + addBuyAllTip"
          >
            {{ language.SHEIN_KEY_PC_15966 }}
            <ClientOnly>
              <count-down-time
                v-if="integrityPromotionInfo.is_count_down == 1"
                class="c-count__down-time"
                :time-stamp="integrityPromotionInfo.end_time"
              />
            </ClientOnly>
          </p>
          <!-- 提示 -->
          <p
            class="cart_item-desc"
            v-html="addBuyAllTip"
          >
          </p>
          <ClientOnly>
            <count-down-time
              v-if="integrityPromotionInfo.is_count_down == 1 && !isSingleMall"
              class="c-count__down-time"
              :time-stamp="integrityPromotionInfo.end_time"
            />
          </ClientOnly>
        </div>
      </div>
      <!-- Pick/Repick/add -->
      <s-button
        v-if="isSingleMall"
        v-enterkey
        :type="['primary','H28PX']"
        @click="isAdd ? handleOpenSupplement() : handleAddBuyAll()"
      >
        {{ operationTxt }}
      </s-button>
      <a
        v-else
        class="operation"
        tabindex="0"
        role="button"
        @click="isAdd ? handleOpenSupplement() : handleAddBuyAll()"
      >
        {{ operationTxt }}
        <sui_icon_more_right_12px
          :is-rotate="locals.GB_cssRight"
          size="12px"
        />
      </a>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import { daEventCenter } from '../../../../services/eventCenter'
import { isObject } from '@shein/common-function'
import { sui_icon_more_right_12px, sui_icon_activity_16px } from '@shein-aidc/icon-vue3'

export default {
  name: 'FullAddOnHeader',
  components: {
    sui_icon_more_right_12px,
    sui_icon_activity_16px
  },
  props: {
    page: {
      type: String,
      default: ''
    },
    integrityPromotionInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    // 是否单mall 单mall展示在所有商品顶部位置，多mall展示在mall下
    isSingleMall: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    ...mapState(['ABTParams', 'isPlatform', 'language', 'cartList', 'soldoutIds', 'locals']),
    ...mapGetters(['cartPromotionMap', 'showThreeStore']),
    promotionContent(){
      return this.integrityPromotionInfo.content || {}
    },
    item () {
      let onSaleList = this.cartList.filter(item => this.soldoutIds.indexOf(item.id) == -1)
      const isPicked = onSaleList.some(item => item.product?.product_promotion_info?.some(p => p.promotion_product_type == '2' && p.promotion_id == this.integrityPromotionInfo.promotion_id))
      return isPicked
    },
    isChecked() {
      let onSaleList = this.cartList.filter(item => this.soldoutIds.indexOf(item.id) == -1)
      const isChecked = onSaleList.some(item => item.product?.product_promotion_info?.some(p => p.promotion_product_type == '2' && p.promotion_id == this.integrityPromotionInfo.promotion_id && item.is_checked))
      return isChecked
    },
    addBuyAllTip () {
      const tip = this.promotionContent.tip
      return isObject(tip) ? tip[this.isChecked ? 1 : 0] : tip
    },
    isAdd () {
      return this.promotionContent.action == 2 ? false : true
    },
    operationTxt(){
      if(this.isAdd){
        return this.language.SHEIN_KEY_PC_15215
      }
      
      return this.item ? this.language.SHEIN_KEY_PC_15969 : this.language.SHEIN_KEY_PC_15968
    },
    isFullPromotion(){
      let promotionId = this.integrityPromotionInfo.promotion_id
      return this.cartPromotionMap[promotionId]?.isFullPromotion || 1
    }
  },
  inject: ['openAddItem'],
  mounted() {
    daEventCenter.triggerNotice({
      daId: '1-7-5-1',
      extraData: {
        typeId: 22,
        isFullPromotion: this.isFullPromotion,
        is_member_gift: 0,
      }
    })
  },
  methods: {
    ...mapMutations(['updateAddForCoupon']),
    handleAddBuyAll(){
      let promotionId = this.integrityPromotionInfo.promotion_id
      this.openAddItem( { type: 'add-buy-all', promotionId: promotionId })
      daEventCenter.triggerNotice({
        daId: '1-7-5-2',
        extraData: {
          id: promotionId,
          typeId: 22,
          isFullPromotion: this.cartPromotionMap[promotionId]?.isFullPromotion,
          is_member_gift: 0,
        }
      })
    },
    handleOpenSupplement(){
      this.updateAddForCoupon({
        show: true, 
        type: 'AddOnV2', // V2版本走重构后的弹窗
        promotion: {
          promotion_id: this.integrityPromotionInfo.promotion_id,
          typeId: 22, // 全场加价购
        },
      })

      daEventCenter.triggerNotice({
        daId: '1-7-5-2',
        extraData: {
          id: this.integrityPromotionInfo.promotion_id,
          typeId: 22,
          isFullPromotion: 1,
          is_member_gift: 0,
        }
      })
    }
  },
}
</script>

<style lang="less" scoped>
.section-content{
  display: flex;
  align-items: center;
  .pro-tag{
    margin-right: 8px;
  }
}
.cart_item-header {
  background: @sui_color_promo_bg;
  .cart-item__header-section {
    padding: 12px 0;
  }
}
</style>
