/*
 * @Author: shuo 
 * @Date: 2019-04-09 15:32:45 
 * @Last Modified by: shuo
 * @Last Modified time: 2019-04-11 11:51:19
 */
/**
 *
 * 事件节流
 * @param {function} fun 事件回调
 * @param {Number} interval 事件节流间隔时间
 * @param {Number} wait 事件停止多久后再次触发事件回调
 * @returns void
 */
export function throttle({fun = null, interval = 1000, wait = 1000} = {}) {
    if (typeof fun !== 'function') return
    let timeout = null,
        startTime = new Date()
    return () => {
        let curTime = new Date()
        window.clearTimeout(timeout)
        // is greater than interval
        if (curTime - startTime >= interval) {
            fun.call(this)
            // reset startTime 
            startTime = curTime
        }
        timeout = window.setTimeout(() => {
            fun.call(this, {isWait: true})
            startTime = curTime
        }, wait)
    }
}