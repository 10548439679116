<script setup>
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { Icon } from '@shein-aidc/icon-vue3'
import { categoryMap } from 'public/src/pages/cart_new/utils/cartFilterLabels.js'
import CheckboxTagItem from '../checkboxTag/CheckboxTagItem.vue'

const store = useStore()
const { GB_cssRight } = gbCommonInfo

const props = defineProps({
  modelValue: {
    type: [ Boolean, Array, String ],
    default: false
  },
  labelsInMorePop: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(['update:modelValue', 'analysis17325'])
const checkValue = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

// 按类别组装筛选标签，返回结构示例：{ USER_BEHAVIOR: [ label1, label2 ], GOODS_INFO: [] }
const categoryLabels = computed(() => {
  const categoryObj = {}
  for (const key in categoryMap) {
    categoryObj[key] = []
  }
  props.labelsInMorePop.forEach(label => {
    for (const key in categoryMap) {
      const { labelTypes } = categoryMap[key]
      if (labelTypes.includes(label?.type)) {
        categoryObj[key].push(label)
        break
      }
    }
  })

  // 过滤空类
  return Object.fromEntries(
    Object.entries(categoryObj)
      .filter(([, value]) => value.length > 0)
  )
})

const lang = computed(() => store?.state?.language)
const filterControlStyle = computed(() => store?.getters?.filterControlStyle)
const moreFilterControl = computed(() => store?.getters?.moreFilterControl)
const showMoreFilterPop = ref(false)

watch(showMoreFilterPop, (val) => {
  if (!val) return
  daEventCenter.triggerNotice({
    daId: '1-7-3-57'
  })
  emit('analysis17325', props.labelsInMorePop, 'filter_menu_inside')
})
</script>

<template>
  <s-popover
    v-model="showMoreFilterPop"
    trigger="hover"
    placemen="bottom-end"
    :prop-style="{
      marginTop: '-4px',
      width: '403px',
      maxWidth: '403px',
      maxHeight: '40vh',
      padding: '16px 5px',
      zIndex: '3999',
      boxShadow: '0px 2px 16px 0px rgba(0, 0, 0, 0.16)',
    }"
  >
    <div
      v-if="showMoreFilterPop"
      class="filter-more__content"
    >
      <div
        v-for="(value, key) in categoryLabels"
        :key="key"
        class="category-filter"
      >
        <span class="category">{{ lang[categoryMap[key]?.langKey] }}</span>
        <CheckboxTagItem
          v-for="(label, index) in value"
          :key="label + index"
          v-model="checkValue"
          class="filter-item__in__popover"
          :label="label.type == 'coupon' ? label.id : label.type"
          :style-config="filterControlStyle"
        >
          <div class="filter-label">
            {{ label.tip }}
          </div>
        </CheckboxTagItem>
      </div>
    </div>
    <template #reference>
      <div
        v-expose="{ id: '1-7-3-56' }"
        class="more-entry"
        :class="{
          'entry-checked': showMoreFilterPop,
          'style-a_entry': filterControlStyle === 'A',
          'style-b_entry': filterControlStyle === 'B',
        }"
      >
        <div
          v-if="moreFilterControl == 'logo'"
          class="logo-entry"
        >
          <Icon
            name="sui_icon_filter_16px"
            size="16px"
            class="logo-icon"
            :is-rotate="GB_cssRight"
          />
        </div>
        <div
          v-if="moreFilterControl == 'text'"
          class="text-entry"
        >
          <span class="more-text">More</span>
          <Icon
            name="sui_icon_more_down_12px_1"
            size="12px"
            class="arrow-icon"
            :is-rotate="GB_cssRight"
          />
        </div>
      </div>
    </template>
  </s-popover>
</template>

<style lang="less" scoped>
.more-entry {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 47px;
  height: 40px;
  box-shadow: -16px 0 10px -10px rgba(204, 204, 204, 0.7);
  cursor: pointer;
  .logo-entry {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    border: solid 1px #E5E5E5;
    .logo-icon {
      font-weight: bold;
      color: #959595!important;/* stylelint-disable-line declaration-no-important */
    }
  }
  .text-entry {
    padding: 0 10px;
    display: flex;
    align-items: center;
    .more-text {
      font-size: 11px;
      color: #666666;
    }
    .arrow-icon {
      color: #bbb!important;/* stylelint-disable-line declaration-no-important */;
    }
  }
  &.entry-checked {
    .logo-entry {
      border-color: #000;
    }
    .logo-icon {
      color: #000!important;/* stylelint-disable-line declaration-no-important */
    }
    .more-text {
      color: #000;
      font-weight: 510;
    }
    .arrow-icon {
      color: #000!important;/* stylelint-disable-line declaration-no-important */
      transform: rotate(180deg);
    }
  }
}
.entry-checked {
  &.style-a_entry {
    .logo-entry {
      border-color: #FA6338;
    }
    .more-text {
      color: #FA6338;
    }
    .logo-icon,
    .arrow-icon {
      color: #FA6338!important;/* stylelint-disable-line declaration-no-important */
    }
  }
}
.filter-more__content {
  max-height: calc(40vh - 32px);
  padding: 0 11px; // 与父级分担16px的padding，是为了定位滚动条的位置
  overflow-x: hidden;
  overflow-y: auto;
  /* 滚动条整体样式 */
  &::-webkit-scrollbar {
    width: 6px; //滚动条宽度
  }
  /* 滚动条轨道样式 */
  &::-webkit-scrollbar-track {
    background-color: #fff; // 滚动条轨道背景色
  }
  /* 滚动条滑块样式 */
  &::-webkit-scrollbar-thumb {
    background-color: #ababab; // 滚动条滑块背景色
    border-radius: 12px; // 滚动条滑块圆角
  }
}
/* stylelint-disable-next-line selector-class-pattern */
.filter-item__in__popover {
  margin-right: 8px;
  margin-bottom: 8px;
  display: inline-flex;
  /* stylelint-disable-next-line selector-class-pattern */
  &.style-a {
    /* stylelint-disable-next-line selector-class-pattern */
    &.cart-checkbox-tag_checked {
      border: 1px solid #FA6338;
    }
  }
  /* stylelint-disable-next-line selector-class-pattern */
  &.style-b {
    /* stylelint-disable-next-line selector-class-pattern */
    &.cart-checkbox-tag_checked {
      color: #000;
      border: 1px solid #000;
      background: #fff;
    }
  }
  .filter-label {
    max-width: 154px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 26px;
    line-height: 26px;
  }
}
.category-filter {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  .category {
    display: block;
    height: 14px;
    line-height: 14px;
    color: #000;
    font-family: "SF Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 510;
    margin-bottom: 8px;
  }
}
</style>
