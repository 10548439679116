<template>
  <div
    class="custom-good-entry"
    @click="onOpen"
  >
    <div>{{ language.SHEIN_KEY_PC_27387 }}</div>
    <sui_icon_more_right_12px size="12px" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index.js'
import CustomContentBox from 'public/src/pages/components/custom-content/index.js'
import { sui_icon_more_right_12px } from '@shein-aidc/icon-vue3'

export default {
  name: 'CustomDetailEntry',
  components: {
    sui_icon_more_right_12px
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState(['language']),
    customInfo() {
      return this.item.product?.customization_info || {}
    }
  },
  methods: {
    onOpen() {
      CustomContentBox.open({
        data: this.customInfo,
      })
      daEventCenter.triggerNotice({
        daId: '1-7-3-42',
        extraData: {
          images: this.customInfo?.preview?.images?.length || 0,
          preview: this.customInfo?.preview?.effects?.length || 0,
          texts: this.customInfo?.preview?.texts?.length || 0,
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.custom-good-entry {
  cursor: pointer;
  display: flex;
  align-items: center;
  background: #f6f6f6;
  color: #222;
  padding: 0 4px 0 6px;
  gap: 4px;
  margin-bottom: 8px;
  font-size: 12px;
}

</style>
