import { createApp } from 'vue'
import { registerBaseContext } from '../common/common_inject'
import container from './container.vue'

export const initRecommend = (params) => {
  const store = params?.store || {}

  let cartRecommend = createApp(container)
  cartRecommend.use(store)
  registerBaseContext(cartRecommend)
  let cartRecommendVm = cartRecommend.mount('.j-recommend-list')

  window.cartRecommend = cartRecommendVm
}
