<template>
  <div
    class="cart_item-header sold-out-group new-soldout-header"
    da-expose-code="cart-sold-out-group"
  >
    <section class="sold-out__item-header">
      <span
        tabindex="0"
      >
        {{ language.SHEIN_KEY_PC_30886 }} ({{ soldoutItems.length }})
      </span>
      <div
        v-if="!isBatchActivePage && soldoutItems.length > 1"
        class="sold-out__view-all"
        :data-goods-num="soldoutItems.length"
        da-event-expose="1-7-3-8"
        da-event-click="1-7-3-9"
        @click="handleViewAll"
      >
        <span>{{ language.SHEIN_KEY_PC_25798 }}</span>
        <sui_icon_more_right_14px
          :is-rotate="locals.GB_cssRight"
          size="14px"
        />
      </div>
      <div
        v-else-if="!isBatchActivePage && soldoutItems.length === 1"
        class="sold-out__view-all"
        :data-goods-num="soldoutItems.length"
        da-event-expose="1-7-3-8"
        da-event-click="1-7-3-9"
        @click="handleSaveAll"
      >
        {{ language.SHEIN_KEY_PC_16398 }}
      </div>
    </section>
    <s-dialog
      v-model:visible="isViewAll"
      :show-close="true"
      class="cart-soldout-dialog"
      :type="`W720`"
      :append-to-body="true"
    >
      <template #title>
        <p class="title">
          {{ language.SHEIN_KEY_PC_14553 }} ({{ soldoutItems.length }})
        </p>
      </template>
      <div>
        <CartSoldOutItem
          v-for="(item, index) in soldoutItems"
          :key="index"
          :item="item"
          :index="cartsId.indexOf(item.id)"
          scroll-parent=".cart-soldout-dialog .sui-dialog__body"
          :is-batch-active-page="isBatchActivePage"
        />
      </div>
      <template #footer>
        <s-button
          :data-goods-num="soldoutItems.length"
          da-event-expose="1-7-3-8"
          da-event-click="1-7-3-9"
          :type="['primary', 'H44PX']"
          @click="handleSaveAll"
        >
          {{ language.SHEIN_KEY_PC_14552 }}
        </s-button>
      </template>
    </s-dialog>
    <common-modal-tip :modal-options="modalOptions" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { SMessage } from '@shein-aidc/sui-message'
import cartLoginView from '../../../utils/cartLoginView'
import { daEventCenter } from '../../../../../services/eventCenter'
import commonModalTip from '../../commonModalTip.vue'
import CartSoldOutItem from './CartSoldOutItem.vue'
import { template, htmlDecode as _htmlDecode } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
const daEventExpose = daEventCenter.getExposeInstance()
import { sui_icon_more_right_14px } from '@shein-aidc/icon-vue3'


export default {
  name: 'SoldOutHeader',
  components: {
    commonModalTip,
    CartSoldOutItem,
    sui_icon_more_right_14px
  },
  props: {
    isBatchActivePage: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      modalOptions: null,
      isViewAll: false,
    }
  },
  computed: {
    ...mapState(['language', 'cartSumQuantity', 'soldoutItems', 'cartsId', 'locals']),
  },
  mounted() {
    this.initExpose()
  },
  methods: {
    ...mapActions(['asyncUpdateCartInfo']),
    template: template,
    htmlDecode: function (text){
      return _htmlDecode({ text: text })
    },
    handleViewAll() {
      this.isViewAll = true
    },
    handleSaveAll: function () {
      this.modalOptions = {
        show: true,
        text: this.htmlDecode(this.language.SHEIN_KEY_PC_17955),
        okCallback: () => {
          this.modalOptions.show = false
          this.saveAllSoldOut()
        },
        cancelCallback: () => {
          this.modalOptions.show = false
        }
      }
    },
    saveAllSoldOut: async function() {
      if (this.soldoutItems.length) {

        try {
          let res = await schttp({
            method: 'POST',
            url: '/api/cart/batchWishGoods/update',
            data: {
              id: this.soldoutItems.map(item => item.id),
              need_drop: 1
            }
          })
          if (res.code == 0) {
            SMessage({
              offset: 136,
              message: this.htmlDecode(this.language.SHEIN_KEY_PC_16402),
              type: 'info'
            })
            sa('send', {
              activity_name: 'expose_save_tips',
              activity_param: ''
            })
            this.asyncUpdateCartInfo({ cartInfo: res.info })
          } else if (res.code == -4 || res.code == -2) {
            // 触发容灾
            SMessage({
              offset: 136,
              type: 'error',
              message: this.htmlDecode(this.language.SHEIN_KEY_PC_17753)
            })
          } else if (Number(res.code) === 400427 && res.info){ // 24小时内收藏数超过限制
            SMessage({
              offset: 136,
              type: 'info',
              message: this.template(res.info.limit, this.language.SHEIN_KEY_PC_19347),
            })
          } else if (res.code && res.code != 0) {
            //没登录
            cartLoginView.login({ type: 'saveforlater', beforeCartSumQuantity: this.cartSumQuantity, cb: this.loginSuc })
          }
        } catch (e) {
          console.log(e)
        }
      }
    },
    loginSuc: async function (result, res){
      if(res.code == 0) {
        await this.asyncUpdateCartInfo({ cartInfo: res.info })
        this.saveAllSoldOut()
      }
    },
    initExpose() {
      this.$nextTick(()=>{
        daEventExpose.subscribe && daEventExpose.subscribe({
          keycode: 'cart-sold-out-group`1-7-3-8',
        })
      })

    },
  }
}
</script>

<style lang="less" scoped>
.new-soldout-header {
  margin-top: 5px;
  .sold-out__item-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 0;
    color: #222;
    font-weight: bold;
    >span {
      font-size: 18px;
    }
  }
  &.sold-out-group.cart_item-header {
    margin-top: 5px;
    padding: 16px 24px 0 16px;
  }
}
.sold-out__view-all {
  font-size: 13px;
  display: flex;
  &:hover {
    cursor: pointer;
  }
}
</style>
