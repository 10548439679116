<template>
  <div>
    <template v-if="locals.IS_RW">
      <EmptyCartRw :to-shop="toShop" />
    </template>
    <template v-else>
      <EmptyCart
        :is-real-show="isRealShow"
      />
    </template>
  </div>
</template>

<script>
import EmptyCart from './EmptyCart'
import EmptyCartRw from './EmptyCartRw'
import { mapState } from 'vuex'
export default {
  
  name: 'EmptyCartIndex',
  components: {
    EmptyCart,
    EmptyCartRw
  },
  props: {
    toShop: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    isRealShow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['locals'])
  },
}
</script>
