import { createSSRApp as createVueApp } from 'vue'
import { registerBaseContext } from '../common/common_inject'
import container from './container.vue'
import countDownTime from './components/CartCountDown.vue'
import numberRoll from 'public/src/pages/common/numberRoll/index.vue'
import { ClientOnly } from '@sheinfe/vue-client-only'

import { cartStore } from './store/index'

export function createApp(context) {
  const store = cartStore()

  const app = createVueApp(container)
  registerBaseContext(app, context)
  app.use(store)
  app.component('CountDownTime', countDownTime)
  app.component('NumberRoll', numberRoll)
  app.component('ClientOnly', ClientOnly)
  
  return { app, store }
}
