<template>
  <div v-if="!IS_RW && labelList.length">
    <swiper-container
      ref="behaviorSwiper"
      init="false"
      class="behavior-swiper"
    >
      <swiper-slide
        v-for="(item, index) in labelList"
        :key="index"
        :index="index"
      >
        <div
          ref="behaviorSwiperContent"
          class="behavior-swiper-content"
        >
          <img
            :src="item.iconSrc"
            width="13"
            height="13"
          />
          <span class="label-text">
            {{ getItemText(item) }}
          </span>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>
</template>

<script>
import { Autoplay } from 'swiper/modules'

import BehaviorLabel from 'public/src/pages/cart_new/utils/behaviorLabel.js'
import { isInViewPort } from 'public/src/pages/cart_new/utils/index.js'
const { IS_RW } = gbCommonInfo

export default {
  name: 'BehaviorLabelsSwiper',
  props: {
    language: {
      type: Object,
      default: () => ({})
    },
    labels: {
      type: Array,
      default: () => ([])
    },
    needSwipe: {
      type: Boolean,
      default: false,
    },
    intersectionSwiper: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      IS_RW,
      swiper: null,
    }
  },
  computed: {
    labelList() {
      return this.labels?.filter((v) => v.labelId && v.showLabelTag) || []
    },
  },
  watch: {
    labelList: {
      handler(n) {
        if (!n?.length) return
        if (typeof window != 'undefined' && n.length > 1) {
          this.initSwiper()
        }
      },
      immediate: true,
    },
    intersectionSwiper(v) {
      if (v) {
        this.swiper?.autoplay?.start?.()
      } else {
        this.swiper?.autoplay?.stop?.()
      }
    },
  },
  beforeUnmount() {
    this.destorySwiper()
  },
  methods: {
    initSwiper() {
      this.$nextTick(() => {
        if (!this.swiper || this.swiper.destroyed) {
          this.destorySwiper()
          // needSwipe TODO
          const config = {
            modules: [Autoplay],
            direction: 'vertical',
            allowTouchMove: false,
            autoplay: {
              delay: 1500,
              disableOnInteraction: false,
            },
            loop: true,
            observer: true,
            observeParents: true,
            on: {
              init: (swiper) => {
                const { realIndex } = swiper
                this.collectSaInfo(realIndex)
              },
              realIndexChange: (swiper) => {
                const { realIndex } = swiper
                this.collectSaInfo(realIndex)
              },
            }
          }
          Object.assign(this.$refs.behaviorSwiper, config)
          this.$refs.behaviorSwiper?.initialize?.()
          this.swiper = this.$refs.behaviorSwiper?.swiper
        } else {
          this.swiper.loopDestroy?.()
          this.swiper.loopCreate?.()
          this.swiper.autoplay?.start?.()
        }
      })
    },
    destorySwiper() {
      if (!this.swiper) return
      if (!this.swiper.destroyed) {
        this.swiper.autoplay?.stop?.()
        this.swiper.destroy?.()
      }
      this.swiper = null
    },
    getItemText(item) {
      return BehaviorLabel.getText(item, this.language)
    },
    collectSaInfo(index) {
      const isInView = isInViewPort(this.$refs.behaviorSwiperContent[index])
      if (!isInView || !this.needSwipe) return
      const item = this.labelList[index]
      if (item) item.isExposed = true
    },
  },
}
</script>

<style lang="less" scoped>
.behavior-swiper {
  margin: 0;
  height: 18px;
}
.behavior-swiper-content {
  overflow: hidden;
  font-size: 12px;
  color: @sui_color_micro_emphasis;
  display: inline-flex;
  align-items: center;
  height: 100%;
  .label-text {
    margin-left: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* rtl:begin:ignore */
    direction: ltr;
  }
}
</style>
