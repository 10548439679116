<script setup>
import { computed } from 'vue'
import { sui_icon_closed_filter_12px_1 } from '@shein-aidc/icon-vue3'
const { GB_cssRight } = gbCommonInfo

const props = defineProps({
  modelValue: {
    type: [Boolean, Array, String],
    default: false
  },
  label: {
    type: String,
    // 传入Array或string时 , label为必传项
  },
  // 是否显示取消icon
  showClose: {
    type: Boolean,
    default: true
  },
  // 标签样式：off-默认线上；A-橙底橙字，B-黑底白字
  styleConfig: {
    type: String,
    default: 'off'
  }
})
const isChecked = computed(() => {
  if(typeof props.modelValue == 'boolean') return props.modelValue
  else if(typeof props.modelValue == 'string') return props.modelValue == props.label
  else if(Array.isArray(props.modelValue)) return props.modelValue.includes(props.label)
  return false
})
const emit = defineEmits(['update:modelValue', 'click-label-val'])
function handleChange() {
  emit('click-label-val', props.label)
  let oldValue = props.modelValue
  let newValue = !oldValue
  if(typeof oldValue == 'boolean') newValue = !oldValue
  else if(typeof oldValue == 'string') newValue = isChecked.value ? 'none' : props.label
  else if(Array.isArray(oldValue)) {
    if(isChecked.value) newValue = oldValue.filter(item => item != props.label)
    else newValue = [...oldValue, props.label]
  }
  emit('update:modelValue', newValue)
}

</script>

<template>
  <label
    class="cart-checkbox-tag"
    :class="{
      'cart-checkbox-tag_checked': isChecked,
      'style-a': styleConfig == 'A',
      'style-b': styleConfig == 'B',
    }"
    @click="handleChange"
  >
    <div class="label-item">
      <slot>{{ label }}</slot>
      <i
        v-if="showClose"
        v-show="isChecked"
        :class="styleConfig == 'off' ? 'icon-checked_default' : 'icon-checked_new'"
        :style="{ transform: GB_cssRight && styleConfig == 'off' ? 'rotate(-90deg)' : 'rotate(0deg)' }"
      >
        <svg
          v-if="styleConfig == 'off'"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0H16V16L7.5 7.5L0 0Z"
            fill="#222222"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.3536 6.06066L13 7.70711L13.7071 7L12.0607 5.35355L13.7071 3.70711L13 3L11.3536 4.64645L9.70711 3L9 3.70711L10.6464 5.35355L9 7L9.70711 7.70711L11.3536 6.06066Z"
            fill="white"
          />
        </svg>
        <sui_icon_closed_filter_12px_1
          v-else
          size="16px"
        />
      </i>
    </div>
  </label>
</template>

<style lang="less" scoped>
.cart-checkbox-tag {
  border: 1px solid #e5e5e5;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  background-color: #fff;
  height: 27px;
  padding: 0 12px;
  cursor: pointer;
  overflow: hidden;
  min-width: fit-content;
  color: #666;
  .label-item {
    height: 100%;
    display: inline-flex;
    align-items: center;
  }
  &.cart-checkbox-tag_checked {
    border: 1px solid #222;
    color: #222;
    .icon-checked_default {
      position: absolute;
      right: 0;
      top: 0;
      width: 16px;
      font-size: 0;
    }
    .icon-checked_new {
      margin-left: 4px;
    }
  }
}
.style-a {
  &.cart-checkbox-tag {
    border-radius: 24px;
    border: 0.5px solid #E5E5E5;
    &.cart-checkbox-tag_checked {
      border: 0.5px solid #FA6338;
      background: #FFF6F3;
      color: #FA6338;
    }
  }
}
.style-b {
  &.cart-checkbox-tag {
    border-radius: 24px;
  }
  &.cart-checkbox-tag_checked {
    border: 0.5px solid #222;
    background: #222;
    color: #FFF;
  }
}
</style>
