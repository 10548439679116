<template>
  <div class="mcheck-empty">
    <div class="empty-container-wrap">
      <!-- 空购物袋 -->
      <div class="empty-bag j-empty-bag empty-bag-full">
        <div class="j-empty-cart fsp-element">
          <empty-cart-vue
            :is-real-show="isRealShow"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmptyCartVue from './index.vue'

export default {
  name: 'EmptyCartContainer',
  components: {
    EmptyCartVue,
  },
  props: {
    isRealShow: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style lang="less">
.mcheck-empty{
  padding-bottom: 30px;
  text-align: center;
}
.empty-container-wrap {
  width: 100%;
  .flexbox();
  .empty-bag {
    background-color: #fff;
    width: 780px;
    padding-bottom: 30px;
    &.empty-bag-full {
      width: 100%;
    }
  }
}
</style>
