<template>
  <div
    v-if="fullGiftTip"
    class="cart_item-header"
    :class="{'not-promoGoods':(!item || showThreeStore) && isSingleMall}"
  >
    <!-- 标题 -->
    <section
      class="cart-item__header-section"
      :class="{'platform-header-section': !isSingleMall}"
    >
      <div class="section-content">
        <sui_icon_gift_16px
          v-if="!isSingleMall"
          class="pro-tag"
          size="16px"
        />
        <div>
          <p
            v-if="isSingleMall"
            class="cart_item-title full-title"
            tabindex="0"
            :aria-label="language.SHEIN_KEY_PC_16033 + fullGiftTip"
          >
            {{ language.SHEIN_KEY_PC_16033 }}
            <ClientOnly>
              <count-down-time
                v-if="countDown.show"
                class="c-count__down-time"
                :time-stamp="countDown.timeStamp"
                show-type="inline-promotion"
                :show-end-tips="true"
              />
            </ClientOnly>
          </p>
          <!-- 提示 -->
          <p
            class="cart_item-desc"
            v-html="fullGiftTip"
          >
          </p>
          <ClientOnly>
            <count-down-time
              v-if="countDown.show && !isSingleMall"
              class="c-count__down-time"
              :time-stamp="countDown.timeStamp"
            />
          </ClientOnly>
        </div>
      </div>
      <!-- 点击按钮 -->
      <s-button
        v-if="isSingleMall"
        v-enterkey
        :type="['primary','H28PX']"
        @click="handleFullGift"
      >
        {{ operationTxt }}
      </s-button>
      <a
        v-else
        class="operation"
        tabindex="0"
        role="button"
        @click="handleFullGift"
      >
        {{ operationTxt }}
        <sui_icon_more_right_12px
          :is-rotate="locals.GB_cssRight"
          size="12px"
        />
      </a>
    </section>
    <GiftList
      v-if="showFullGiftsList"
      :gift-list="getGiftLift"
      :promotion-id="promotionId"
      :promotion-type="promotionTypeId"
      :fulfill-status="fulfillStatus"
      :is-show="showFullGiftsList"
      @handle-view-all="handleFullGift(false)"
    />
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import dayjs from 'dayjs'
import { daEventCenter } from '../../../../services/eventCenter'
import GiftList from './giftList/GiftList.vue'
import { isObject } from '@shein/common-function'
import { sui_icon_more_right_12px, sui_icon_gift_16px } from '@shein-aidc/icon-vue3'

export default {
  name: 'FullGiftHeader',
  components: {
    GiftList,
    sui_icon_more_right_12px,
    sui_icon_gift_16px
  },
  props: {
    page: {
      type: String,
      default: ''
    },
    integrityPromotionInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    // 是否单mall 单mall展示在所有商品顶部位置，多mall展示在mall下
    isSingleMall: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      finished: false,
    }
  },
  computed: {
    ...mapState(['ABTParams', 'isPlatform', 'language', 'cartInfo', 'cartList', 'soldoutIds', 'locals']),
    ...mapGetters(['cartPromotionMap', 'showThreeStore']),
    promotionContent() {
      return this.integrityPromotionInfo.content || {}
    },
    item() {
      let onSaleList = this.cartList.filter(item => this.soldoutIds.indexOf(item.id) == -1)
      const isPicked = onSaleList.some(item => item.product?.product_promotion_info?.some(p => p.promotion_product_type == '1' && p.promotion_id == this.integrityPromotionInfo.promotion_id))
      return isPicked
    },
    // 选择赠品是否有效
    isChecked() {
      let onSaleList = this.cartList.filter(item => this.soldoutIds.indexOf(item.id) == -1)
      const isPicked = onSaleList.some(item => item.product?.product_promotion_info?.some(p => p.promotion_product_type == '1' && p.promotion_id == this.integrityPromotionInfo.promotion_id && item.is_checked))
      return isPicked
    },
    fullGiftTip(){
      const tip = this.promotionContent.tip
      if (isObject(tip)) {
        if (this.isSheinClubGiftPromotion) return tip[this.item ? 1 : 0]
        return tip[this.isChecked ? 1 : 0]
      }
      return tip
    },
    showAddFullGift(){
      return this.promotionContent.action == 1
    },
    isFullPromotion(){
      let promotionId = this.integrityPromotionInfo.promotion_id
      return this.cartPromotionMap[promotionId]?.isFullPromotion || 1
    },
    getGiftLift() {
      return this.promotionContent.showFullGiftList || []
    },
    showFullGiftsList(){
      return !this.item && this.getGiftLift.length
    },
    promotionId() {
      return this.integrityPromotionInfo.promotion_id || ''
    },
    promotionTypeId() {
      return this.integrityPromotionInfo.type_id || 4
    },
    isSheinClubGiftPromotion() {
      return [8, 14].includes(this.integrityPromotionInfo.promotion_logo_type)
    },
    countDown() {
      if (this.isSheinClubGiftPromotion && this.integrityPromotionInfo.promotion_logo_type === 8) {
        const target = this.cartInfo.promotion_member_info?.find?.(f => f.promotion_id === this.promotionId)
        const timeStamp = target?.member_equity_end_time ?? ''
        const lessThan72 = timeStamp && dayjs(timeStamp * 1000).diff(dayjs(), 'hour') < 72
        return {
          show: !!target && lessThan72,
          timeStamp,
        }
      }
      return {
        show: this.integrityPromotionInfo.is_count_down == 1,
        timeStamp: this.integrityPromotionInfo.end_time,
      }
    },
    fulfillStatus() {
      return this.promotionContent.fulfillStatus || 0
    },
    operationTxt(){
      return this.showAddFullGift ? this.language.SHEIN_KEY_PC_15215 : (this.item ? this.language.SHEIN_KEY_PC_15969 : this.language.SHEIN_KEY_PC_15968)
    }
  },
  inject: ['openAddItem'],
  mounted() {
    daEventCenter.triggerNotice({
      daId: '1-7-5-1',
      extraData: {
        typeId: 4,
        isFullPromotion: this.isFullPromotion,
        is_member_gift: +this.isSheinClubGiftPromotion,
      }
    })
  },
  methods: {
    ...mapMutations(['updateAddForCoupon']),
    handleFullGift(isAnalysis = true){
      const { promotion_id, type_id } = this.integrityPromotionInfo
      if(isAnalysis) {
        daEventCenter.triggerNotice({
          daId: '1-7-5-2',
          extraData: {
            id: promotion_id,
            typeId: 4,
            isFullPromotion: this.cartPromotionMap[promotion_id]?.isFullPromotion,
            is_member_gift: +this.isSheinClubGiftPromotion,
          }
        })
      }
      if(isAnalysis && this.showAddFullGift) {
        this.updateAddForCoupon({
          show: true, 
          type: 'AddOnV2', // V2版本走重构后的弹窗
          promotion: {
            promotion_id,
            typeId: type_id,
          },
        })
        return
      }
      const type = this.integrityPromotionInfo.promotion_logo_type === 8  ? 'club-gift' : 'full-gift'
      this.openAddItem( { type, promotionId: promotion_id })
    },
  }
}
</script>

<style lang="less" scoped>
.section-content{
  display: flex;
  align-items: center;
  .pro-tag{
    margin-right: 8px;
  }
}
.cart_item-header {
  background: @sui_color_promo_bg;
  .cart-item__header-section {
    padding: 12px 0;
  }
}
</style>
