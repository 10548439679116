/*
* @Author: shuo
* @Date:   2019-03-23 17:57:18
 * @Last Modified by: shuo
 * @Last Modified time: 2019-04-09 15:26:30
*/
 /**
  * 保留小数， 精度100
  * @param {*} value 
  * @param {*} toFixedNum 保留几位小数点
  */
export function toDecimal(value, toFixedNum) {
	let precision = Math.pow(10, Number(toFixedNum) + 2)
	return (Math.round(value * precision) / precision).toFixed(toFixedNum)
}