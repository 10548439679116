const couponInfoKey = 'addOnItem__couponData'

export const setCouponDataForAddOnItem = (data) => {
  if (typeof window === 'undefined') return
  window.sessionStorage.setItem(couponInfoKey, JSON.stringify(data))
  return data
}

export const getCouponDataForAddOnItem = () => {
  if (typeof window === 'undefined') return
  const res = window.sessionStorage.getItem(couponInfoKey)

  window.sessionStorage.removeItem(couponInfoKey)

  try {
    return JSON.parse(res)
  } catch (error) {
    return
  }
}
